import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';
import HTTP from '../../api/HTTP';

const ServiceEditCustDocPop = (props) => {
  const { showPopup, hidePopup, selServiceId, selReqdocid, popInsert, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const [requiredDocData, setRequiredDocData] = useState({});
  const [opId, setOpId] = useState(1);

  useEffect(() => {
    const fetchRequiredDocData = async () => {
      try {
        const dataArray = await HTTP.get(`/service/servicereqDoc/${selServiceId}/${selReqdocid}`);
        setRequiredDocData(dataArray.data[0]);
      } catch (err) {}
    };
    if (selReqdocid && showPopup && popInsert === 2) {
      fetchRequiredDocData();
    } else if (selReqdocid && showPopup && popInsert === 1) {
      setRequiredDocData({});
    }
  }, [selServiceId, selReqdocid, showPopup]);

  const requiredDocRecord = {
    serviceid: selServiceId,
    reqdocid: requiredDocData.reqdocid || -1,
    doc_title: requiredDocData.doc_title || '',
    doc_usage_advice: requiredDocData.doc_usage_advice || '',
    doc_content: requiredDocData.doc_content || '',
    validon: requiredDocData.validon || new Date(),
    validuntil: requiredDocData.validuntil || '',
  };

  const saveRequiredDoc = async () => {
    try {
      await HTTP.post(
        '/service/servicedocreq',
        JSON.stringify({
          operation: popInsert,
          serviceid: selServiceId,
          reqdocid: requiredDocRecord.reqdocid,
          doc_title: requiredDocRecord.doc_title,
          doc_usage_advice: requiredDocRecord.doc_usage_advice,
          doc_content: requiredDocRecord.doc_content,
          validon: requiredDocRecord.validon,
          validuntil: requiredDocRecord.validuntil,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async (noteFormRef) => {
    const result = noteFormRef.validationGroup.validate();
    if (result.isValid) {
      saveRequiredDoc();
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title='Required Customer Document'
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              ref={noteFormRef}
              formData={requiredDocRecord}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              validationGroup='RequiredDocData'
            >
              <Item itemType='group' colCount={4}>
                <SimpleItem dataField='doc_title' isRequired={true} colSpan={2}>
                  <Label text='Document Title' />
                </SimpleItem>
                <SimpleItem dataField='validon' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                  <Label text='Valid on' />
                </SimpleItem>
                <SimpleItem dataField='validuntil' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                  <Label text='valid until' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group'>
                <SimpleItem dataField='doc_usage_advice' isRequired={true} colSpan={2}>
                  <Label text='Document Usage Advice' />
                </SimpleItem>
              </Item>
              <Item itemType='group' colCount={1}>
                <SimpleItem
                  dataField='doc_content'
                  editorType='dxTextArea'
                  isRequired={true}
                  editorOptions={{ height: 450 }}
                >
                  <Label text='Content' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                // visible={openedToEdit === 1}
                visible={true}
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                validationGroup='RequiredDocData'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={50}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServiceEditCustDocPop;
