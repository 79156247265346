import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';
import { useCaseNote } from '../../hooks/note-hook';

import ArrayStore from 'devextreme/data/array_store';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useHttpClient } from '../../hooks/http-hook';

import { CheckBox } from 'devextreme-react/check-box';
import { TextArea } from 'devextreme-react/text-area';
import { Toast } from 'devextreme-react/toast';

import InfoPop from '../../ui_elements/InfoPop';
import HTTP from '../../api/HTTP';

const ClientApprovalPop = (props) => {
  const { showPopup, hidePopup, user, clientid, selServiceid, selProductid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { sendCaseNoteSilent, checkClientApprovalState } = useCaseNote();
  const [lpProvApproval, setLpProvApproval] = useState([]);
  const [selApprovalValue, setSelApprovalValue] = useState(0);
  const [approveClient, setApproveClient] = useState(true);
  const [additionalDetails, setAdditionalDetails] = useState(false);
  const [rejectClientApproval, setRejectClientApproval] = useState(false);
  const [commentText, setCommentText] = useState('Comment');
  const [messageText, setMessageText] = useState('');
  const [toastConfigVisible, setToastConfigVisible] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  useEffect(() => {
    const fetchProvApproval = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/46');
        setLpProvApproval(dataArray.data);
      } catch (err) {}
    };

    fetchProvApproval();
  }, []);

  const stProvApproval = new ArrayStore({
    key: 'lp_id',
    data: lpProvApproval,
  });

  const checkedOptions = (selValue) => {
    switch (selValue) {
      case 1:
        setApproveClient(true);
        setAdditionalDetails(false);
        setRejectClientApproval(false);
        setCommentText('Comment');
        break;
      case 2:
        setApproveClient(false);
        setAdditionalDetails(true);
        setRejectClientApproval(false);
        setCommentText('Please let us know which details you need');
        break;
      case 3:
        setApproveClient(false);
        setAdditionalDetails(false);
        setRejectClientApproval(true);
        setCommentText('Please let us know why you reject the client approval');
        break;
      default:
        setApproveClient(true);
        setAdditionalDetails(false);
        setRejectClientApproval(false);
    }
  };

  const saveApprovalDecision = async () => {
    let selSituation = 1;

    if (messageText.length === 0) {
      if (additionalDetails) {
        
        setToastConfigVisible(true);
        return;
      } else if (rejectClientApproval) {
       
        setToastConfigVisible(true);
        return;
      }
    }

    if (approveClient) {
      selSituation = 11;
      const freeAccounts = parseInt(await availableServiceAccounts());
      console.log(freeAccounts);

      if (freeAccounts > 0) {
        const serviceCreated = await approveClientServiceAccount();
        console.log(102, serviceCreated);
        if (serviceCreated) {
          await sendCaseNoteSilent(user, clientid, selSituation, messageText);
          await checkClientApprovalState(user, clientid);
        }
      } else {
        await setShowInfoPopup(true);
      }
    } else if (additionalDetails) {
      selSituation = 20;
      await sendCaseNoteSilent(user, clientid, selSituation, messageText);
      saveClientAccessState(2);
    } else if (rejectClientApproval) {
      selSituation = 12;
      saveClientAccessState(6);
      await sendCaseNoteSilent(user, clientid, selSituation, messageText);
    }

    hidePopup(true);
  };

  const saveClientAccessState = async (anState) => {
    const response = await HTTP.post(
      '/client/clientaccessstate',
      JSON.stringify({ clientid: clientid, accessstate: anState })
    );
    const data = await response.data;
  };

  const availableServiceAccounts = async () => {
    try {
      const dataArray = await HTTP.get(`/service/freeserviceaccounts/${selServiceid}`);
      return dataArray.data[0].cnt_accounts;
    } catch (err) {}
  };

  async function createClientAccountAtServiceProvider(clientAccount, token) {
    const response = await HTTP.post(
      '/provider/createclientaccountatserviceprovider',
      JSON.stringify({ clientAccount: clientAccount })
    );
    const data = await response.data;
    console.log(data);
  }

  const approveClientServiceAccount = async () => {
    try {
      const approvalResult = await HTTP.post(
        '/provider/approveclient',
        JSON.stringify({
          serviceid: selServiceid,
          clientid: clientid,
          productid: selProductid,
        })
      );

      console.log(approvalResult.data[0].approve_account_service_simulator);

      // Get provider client asset accounts
      const clientAccounts = await HTTP.post(
        '/provider/clientassetaccounts',
        JSON.stringify({
          clientid: clientid,
          serviceid: selServiceid,
          productid: selProductid,
        })
      );

      console.log(clientAccounts.data);

      // Create client asset accounts
      for (let i = 0; i < clientAccounts.data.length; i++) {
        let clientAccount = {
          createclientaccount: true,
          categoryident: approvalResult.data[0].approve_account_service_simulator.categoryident,
          providerid: user.providerid,
          clientid: clientid,
          assetaccountid: clientAccounts.data[i].assetaccountid,
          accountidentifier: clientAccounts.data[i].asset_shortid,
          assetid: clientAccounts.data[i].assetid,
          accountamount: 0,
          status: 'approved',
        };

        await createClientAccountAtServiceProvider(clientAccount, user.token);
      }
      console.log(1, approvalResult.data[0].approve_account_service_simulator.approved);

      return approvalResult.data[0].approve_account_service_simulator.approved;
    } catch (err) {
      console.log(err);
    }
  };

  const hideInfoPop = () => {
    setShowInfoPopup(false);
  };

  return (
    <Popup
      title={'Account'}
      width={640}
      height={420}
      visible={showPopup}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <InfoPop
        showPopup={showInfoPopup}
        hidePopup={hideInfoPop}
        popTitle='Info'
        popMessage='The Service does not consist of free "Accounts". Please add these to enable Client Approval'
      />
      <ResponsiveBox>
        <Row ratio={0.2} />
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <ResponsiveBox id='client-approval-pop-cbx'>
            <Row ratio={1} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <CheckBox
                text='Approve Client'
                value={approveClient}
                onValueChanged={(e) => {
                  if (e.value === true) {
                    checkedOptions(1);
                  }
                }}
              />
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <CheckBox
                text='Request Additional Details'
                value={additionalDetails}
                onValueChanged={(e) => {
                  if (e.value === true) {
                    checkedOptions(2);
                  }
                }}
              />
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={2} />
              <CheckBox
                text='Reject Client Approval'
                value={rejectClientApproval}
                onValueChanged={(e) => {
                  if (e.value === true) {
                    checkedOptions(3);
                  }
                }}
              />
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <TextArea
            id='client-approval-pop-form'
            placeholder={commentText + '. Enter your text here...'}
            height={200}
            width={590}
            valueChangeEvent='keyup'
            onValueChanged={(e) => {
              setMessageText(e.value);
              // console.log(e.value);
            }}
          />
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={2} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                // validationGroup='serviceFeatureValGrp'
                // useSubmitBehavior={true}
                onClick={(e) => {
                  saveApprovalDecision();
                }}
              />
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={hidePopup}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
      <Toast
        visible={toastConfigVisible}
        message={commentText}
        type='error'
        displayTime={4000}
        onHiding={() => {
          setToastConfigVisible(false);
        }}
      />
      ;
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ClientApprovalPop;
