import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import CasesNotesToPop from '../operations/CasesNotesToPop';
import ClientAccessPop from './ClientAccessPop';
import ClientApprovalPop from './ClientApprovalPop';
import HTTP from '../../api/HTTP';

const ClientsApprovedAccess = (props) => {
  const { tabVisibleIndex, selSignupClientid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteEntryId, setNoteEntryId] = useState(-1);
  const [noteCaseEntryid, setNoteCaseEntryid] = useState(-1);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);
  const [contactClientMail, setContactClientMail] = useState('');
  const [clientApprovalStateData, setClientApprovalStateData] = useState([]);
  const [showClientAccessPop, setShowClientAccessPop] = useState(false);
  const [showClientApprovalPop, setShowClientApprovalPop] = useState(false);
  const [caseSituationAction, setCaseSituationAction] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(-1);
  const [selectedProductId, setSelectedProductId] = useState(-1);
  const [approvalReload, SetApprovalReload] = useState(false);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selSignupClientid}`);
        setSignupClientDatalist(dataArray.data[0]);

        const dataArrayTwo = await HTTP.get(
          `/client/getclientserviceapprovalstate/${selSignupClientid}/${user.providerid}`,
          'GET'
        );
        setClientApprovalStateData(dataArrayTwo.data);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [selSignupClientid, approvalReload]);

  const stclientApprovalStateData = new ArrayStore({
    key: 'assetaccountid',
    data: clientApprovalStateData,
  });

  const dcClientApprovalStateData = new DataSource({
    store: stclientApprovalStateData,
  });

  const contactSelectedClient = () => {
    setContactClientid(signupClientDatalist.signupid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(signupClientDatalist.portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.clientid);
    setContactPortalid(signupClientDatalist.portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.signupid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);
    await setNoteEntryId(-1);
    await setNoteCaseEntryid(-1);
    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
    await reloadNewsGridCondition(true);
  };

  const showClientAccessDialog = () => {
    setShowClientAccessPop(true);
  };

  const hideClientAccessPop = () => {
    setShowClientAccessPop(false);
  };

  const showClientApprovalDialog = () => {
    setShowClientApprovalPop(true);
  };

  const hideClientApprovalPop = async () => {
    await setShowClientApprovalPop(false);
    await reloadNewsGridCondition(true);
    SetApprovalReload(!approvalReload);
  };

  return (
    <React.Fragment>
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={noteEntryId}
        caseEntryid={noteCaseEntryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        contactClientMail={contactClientMail}
        clientData={signupClientDatalist}
        caseSituationAction={caseSituationAction}
      />
      <ClientAccessPop showPopup={showClientAccessPop} hidePopup={hideClientAccessPop} user={user}></ClientAccessPop>
      <ClientApprovalPop
        showPopup={showClientApprovalPop}
        hidePopup={hideClientApprovalPop}
        user={user}
        clientid={signupClientDatalist.signupid}
        selServiceid={selectedServiceId}
        selProductid={selectedProductId}
      />
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1.8} />
        <Col ratio={9} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Account</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Approve'
              type='default'
              stylingMode='outlined'
              onClick={showClientApprovalDialog}
            />
          </div>
          <div id='hm-btn-section-11'>
            <Button
              width={120}
              height={36}
              text='Access'
              type='success'
              stylingMode='outlined'
              onClick={showClientAccessDialog}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='client-access-info'>
            <table>
              <tbody>
                <tr>
                  <td width='100px'>Client Access:</td>
                  <td width='100px'>Active</td>
                  <td width='60px'>Since:</td>
                  <td width='100px'>01.01.2022</td>
                </tr>
              </tbody>
            </table>
          </div>

          <DataGrid
            id='client-access-grid'
            dataSource={dcClientApprovalStateData}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={true}
            showBorders={true}
            showColumnLines={true}
            showRowLines={false}
            autoNavigateToFocusedRow={true}
            showColumnHeaders={true}
            // focusedRowKey={selectedClientid}
            onFocusedRowChanged={(e) => {
              if (e.row) {
                setSelectedServiceId(e.row.data.serviceid);
                setSelectedProductId(e.row.data.productid);
              }
            }}
          >
            <Column dataField='assetaccountid' visible={false}></Column>
            <Column dataField='providerid' visible={false}></Column>
            <Column dataField='serviceid' visible={false}></Column>
            <Column dataField='productid' visible={false}></Column>
            <Column dataField='productname' caption='Product' width={150} visible={false} />
            <Column dataField='servicename' caption='Service' width={300} />
            <Column dataField='providername' caption='Provider' width={120} visible={false} />
            <Column dataField='provider_approved' caption='Approved' width={80} />
            <Column dataField='access_state_txt' caption='Access State' width={120} />
            <Column dataField='access_state_time' caption='on' dataType='datetime' width={120} />
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedAccess;
