import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import CasesNotesToPop from '../operations/CasesNotesToPop';
import HTTP from '../../api/HTTP';

const ClientsApprovedDetails = (props) => {
  const { tabVisibleIndex, selSignupClientid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteEntryId, setNoteEntryId] = useState(-1);
  const [noteCaseEntryid, setNoteCaseEntryid] = useState(-1);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);
  const [contactClientMail, setContactClientMail] = useState('');

  const [caseSituationAction, setCaseSituationAction] = useState(false);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selSignupClientid}`);
        setSignupClientDatalist(dataArray.data[0]);
      } catch (err) {}
    };
    if (selSignupClientid) {
      fetchSignupClientData();
    }
  }, [selSignupClientid]);

  const contactSelectedClient = () => {
    setContactClientid(signupClientDatalist.signupid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(signupClientDatalist.portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.clientid);
    setContactPortalid(signupClientDatalist.portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.signupid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);
    await setNoteEntryId(-1);
    await setNoteCaseEntryid(-1);
    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
    await reloadNewsGridCondition(true);
  };

  return (
    <React.Fragment>
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={noteEntryId}
        caseEntryid={noteCaseEntryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        contactClientMail={contactClientMail}
        clientData={signupClientDatalist}
        caseSituationAction={caseSituationAction}
      />

      <ResponsiveBox>
        <Col ratio={1.5} />
        <Col ratio={9} />
        <Row ratio={1} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Merchant</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Contact'
              type='default'
              stylingMode='outlined'
              onClick={contactClientMerchant}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='client-details-info'>
            <div className='note-details-container-small'>
              <div className='note-details-wrapper'>
                <div className='note-details-personally'>
                  <div className='note-details-personally-colorcode'>
                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Name:</div>
                      <div className='note-details-personally-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.cl_salutation}{' '}
                        {signupClientDatalist && signupClientDatalist.cl_firstname}{' '}
                        {signupClientDatalist && signupClientDatalist.cl_lastname}
                      </div>
                    </div>

                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Nationality:</div>
                      <div className='note-details-personally-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.cl_nationality}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='note-details-wrapper'>
                <div className='note-details-client'>
                  <div className='note-details-client-colorcode'></div>
                  <div className='note-details-client-infoText'>Merchant/Partner:</div>
                  <div className='note-details-client-outputText'>
                    {signupClientDatalist && signupClientDatalist.partnername}
                  </div>
                </div>
              </div>

              <div className='note-details-wrapper'>
                <div className='note-details-personally'>
                  <div className='note-details-personally-colorcode'>
                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Residence:</div>
                      <div className='note-details-personally-block-outputText'>
                        <span>{signupClientDatalist && signupClientDatalist.cl_street}</span>
                        <br />
                        <span>{signupClientDatalist && signupClientDatalist.cl_plc}</span>{' '}
                        <span>{signupClientDatalist && signupClientDatalist.cl_city}</span>
                        <br />
                        <span>{signupClientDatalist && signupClientDatalist.cl_countryofresidence}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='note-details-wrapper'>
                <div className='note-details-personally'>
                  <div className='note-details-personally-colorcode'>
                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>E-Mail:</div>
                      <div className='note-details-personally-block-outputText-verified'>
                        {signupClientDatalist && signupClientDatalist.cl_email_verified ? 'verified' : 'not verified'}
                      </div>
                      <div className='note-details-personally-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.cl_email}
                      </div>
                    </div>

                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Phone:</div>
                      <div className='note-details-personally-block-outputText-verified'>
                        {signupClientDatalist && signupClientDatalist.cl_mobilephone_verified
                          ? 'verified'
                          : 'not verified'}
                      </div>
                      <div className='note-details-personally-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.cl_mobilephone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='note-details-wrapper'>
                <div className='note-details-personally'>
                  <div className='note-details-personally-colorcode'>
                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Company:</div>
                      <div className='note-details-personally-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.cy_name}
                      </div>
                    </div>

                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Registered in:</div>
                      <div className='note-details-personally-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.cy_regin_country}
                      </div>
                    </div>

                    <div className='note-details-personally-block'>
                      <div className='note-details-personally-block-infoText'>Address:</div>
                      <div className='note-details-personally-block-outputText'>
                        <span>{signupClientDatalist && signupClientDatalist.cy_street}</span>
                        <br />
                        <span>{signupClientDatalist && signupClientDatalist.cy_plc}</span>{' '}
                        <span>{signupClientDatalist && signupClientDatalist.cy_city}</span>
                        <br />
                        <span>{signupClientDatalist && signupClientDatalist.cy_country}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='note-details-wrapper'>
                <div className='note-details-account'>
                  <div className='note-details-account-colorcode'>
                    <div className='note-details-account-block'>
                      <div className='note-details-account-block-infoText'>Signup Started:</div>
                      <div className='note-details-account-block-outputText'>
                        {signupClientDatalist &&
                          signupClientDatalist.signupdate &&
                          signupClientDatalist &&
                          signupClientDatalist.signupdate.slice(0, 10)}
                        {', '}
                        {signupClientDatalist &&
                          signupClientDatalist.signupdate &&
                          signupClientDatalist &&
                          signupClientDatalist.signupdate.slice(11, 16)}
                      </div>
                    </div>

                    <div className='note-details-account-block'>
                      <div className='note-details-account-block-infoText'>KYC:</div>
                      <div className='note-details-account-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.gp_passed ? 'Passed ' : ''}
                        {signupClientDatalist && signupClientDatalist.gp_failed
                          ? 'Failed ' + signupClientDatalist && signupClientDatalist.gp_failed_reason
                          : ''}
                      </div>
                    </div>
                    <div className='note-details-account-block'>
                      <div className='note-details-account-block-infoText'>Risk Level:</div>
                      <div className='note-details-account-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.risklevel}
                      </div>
                    </div>
                    <div className='note-details-account-block'>
                      <div className='note-details-account-block-infoText'>Product:</div>
                      <div className='note-details-account-block-outputText'>
                        {signupClientDatalist && signupClientDatalist.productname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedDetails;
