import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import './ServiceDetails.css';

import Tabs from 'devextreme-react/tabs';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow, Editing, Lookup, MasterDetail } from 'devextreme-react/data-grid';

import ServiceFeeGridDetails from '../../detailgrids/ServiceFeeGridDetails';
import HTTP from '../../api/HTTP';

const ServiceDetailsCostRates = (props) => {
  const { selServiceId, user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [costRateHeaderList, setCostRateHeaderList] = useState({});
  const [costRiskEntriesList, setCostRiskEntriesList] = useState([]);

  useEffect(() => {
    const fetchCostRatesHeader = async () => {
      try {
        const dataArray = await HTTP.get(`/service/singleservicecostrateheader/${selServiceId}`);
        setCostRateHeaderList(dataArray.data[0]);

        let anCostEntryId = -1;

        if (dataArray.data[0].costentryid) {
          anCostEntryId = dataArray.data[0].costentryid;
        }

        const costRiskDataArray = await HTTP.get(`/service/viewcostriskentries/${anCostEntryId}`);

        setCostRiskEntriesList(costRiskDataArray.data);
      } catch (err) {}
    };

    if (selServiceId && selServiceId > -1) {
      fetchCostRatesHeader();
    }
  }, [selServiceId]);

  const costRateHeader = {
    serviceid: (costRateHeaderList && costRateHeaderList.serviceid) || -1,
    costentryid: (costRateHeaderList && costRateHeaderList.costentryid) || -1,
    generated: costRateHeaderList && costRateHeaderList.generated,
    valid_on: costRateHeaderList && costRateHeaderList.valid_on,
    valid_until: costRateHeaderList && costRateHeaderList.valid_until,
    currency: (costRateHeaderList && costRateHeaderList.currency) || '',
    entrynumber: (costRateHeaderList && costRateHeaderList.entrynumber) || '',
    comment: (costRateHeaderList && costRateHeaderList.comment) || '',
    userid: (costRateHeaderList && costRateHeaderList.userid) || -1,
    active: (costRateHeaderList && costRateHeaderList.active) || false,
    active: costRateHeaderList && costRateHeaderList.active_date,
  };

  const stCostRiskEntriesData = new ArrayStore({
    key: ['riskid'],
    data: costRiskEntriesList,
  });

  const dcCostRiskEntriesData = new DataSource({
    store: stCostRiskEntriesData,
  });

  function navigateToHome() {
    history.goBack();
  }

  const openServiceFeeEdit = async () => {
    try {
      const dataArray = await HTTP.get(
        `/service/costrateworkcopy/${costRateHeader.serviceid}/${costRateHeader.costentryid}`
      );

      user.costrates = {
        selServiceid: costRateHeader.serviceid,
        selCostEntryid: costRateHeader.costentryid,
        workId: dataArray.data[0].work_copy_costrates,
        originid: 2,
      };

      history.push({
        pathname: '/servicefees_edit',
      });
    } catch (err) {}
  };

  return (
    <div id='service-details-pricing-box'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Service Sell Rates</div>
      </div>
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />
        <Col />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />

          <ResponsiveBox>
            <Col ratio={0.3} screen='md lg sm xs' />
            <Col ratio={3} />
            <Row />

            <Item>
              <Location screen='md lg sm xs' row={0} col={-1} />
              <div id='svpro-hm-btn-section-1'>
                <Button
                  width={120}
                  height={50}
                  text='Edit'
                  type='normal'
                  stylingMode='outlined'
                  disabled={true}
                  onClick={openServiceFeeEdit}
                />
              </div>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} colspan={2} />
              <Form
                id='service-cost-entries-form-tab'
                formData={costRateHeader}
                labelLocation={'left'}
                colCountByScreen={colCountByScreen}
                scrollingEnabled={true}
              >
                <Item itemType='group' colCount={5}>
                  <SimpleItem dataField='currency' editorOptions={{ readOnly: true }}>
                    <Label text='Currency' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='valid_on'
                    dataType='date'
                    editorType='dxDateBox'
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text='Valid on' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='valid_until'
                    dataType='date'
                    editorType='dxDateBox'
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text='Valid on' />
                  </SimpleItem>
                </Item>
                <Item itemType='group' colCount={5}>
                  <SimpleItem
                    dataField='active'
                    dataType='boolean'
                    editorType='dxCheckBox'
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text='Active' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='active_date'
                    dataType='date'
                    editorType='dxDateBox'
                    editorOptions={{ readOnly: true }}
                  >
                    <Label text='Active on' />
                  </SimpleItem>
                </Item>

                <Item>
                  <DataGrid
                    height={400}
                    width={1000}
                    dataSource={dcCostRiskEntriesData}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    focusedRowEnabled={true}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    autoNavigateToFocusedRow={true}
                    // onFocusedRowChanged={e => {
                    //   setRiskGridData(e.row.data);
                    //   setSelRiskid(e.row.data.riskid);
                    // }}
                  >
                    <Column dataField='serviceid' visible={false} />
                    <Column dataField='costentryid' visible={false} />
                    <Column dataField='riskid' visible={false} />
                    <Column dataField='risklevel' caption='Risk Level' width='150px' allowEditing={false} />
                    <Column dataField='rates_set' caption='Rates Set' width='90px' visible={false} />
                    <Column dataField='not_applicable' caption='Not Applicable' width='120px' />
                    <Column dataField='setupfee' caption='Setup Fee' dataType='number' width='100' />
                    <Column dataField='recurringfee' caption='Service Fee' dataType='number' width='100' />
                    <Column dataField='recperiod' caption='Recurring' width='100'></Column>
                    <MasterDetail enabled={true} component={ServiceFeeGridDetails} />
                  </DataGrid>
                </Item>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'unterlined' };

export default ServiceDetailsCostRates;
