import React, { useEffect } from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import InternalMessagingHelper from './InternalMessagingHelper';
import notify from 'devextreme/ui/notify';
import { downloadFileFromBuffer } from '../../../utils/attachesHelper';
import moment from 'moment';

function MessagesList({
  threadInfo,
  isLoading = false,
  height = 100,
  messageslist,
  onLoadMore = null,
  className,
  messageListRef,
  openedBy,
}) {
  const handleDownloadFile = (documentId) => {
    InternalMessagingHelper.getFileContent(documentId, openedBy)
      .then((data) => {
        downloadFileFromBuffer(data.fileContent.data, data.fileName);
      })
      .catch((e) => {
        notify('An error occurred while downloading the file', 'error', 2000);
      });
  };

  const getNameById = (id) => {
    const result = [];
    if (threadInfo && threadInfo.persons) {
      const foundPerson = threadInfo.persons.find((person) => {
        return person.customerId === id;
      });
      if (foundPerson && typeof foundPerson.firstName === 'string') {
        result.push(foundPerson.firstName);
      }

      if (foundPerson && typeof foundPerson.lastName === 'string') {
        result.push(foundPerson.lastName);
      }
    }
    return result.join(' ');
  };

  const getFontIconByExtension = (extension = 'other') => {
    const FILE_EXTENSIONS = {
      pdf: 'pdffile',
      doc: 'docfile',
      docx: 'docxfile',
      txt: 'txtfile',
      xls: 'xlsfile',
      jpg: 'image',
      jpeg: 'image',
      png: 'image',
      avi: 'video',
      zip: 'toolbox',
      rar: 'toolbox',
      other: 'file',
    };

    const foundExtension = FILE_EXTENSIONS[extension];
    if (!foundExtension) {
      return FILE_EXTENSIONS.other;
    }
    return FILE_EXTENSIONS[extension];
  };

  const getFileExtension = (fileName) => {
    if (fileName ?? undefined) {
      return fileName.split('.').pop().toLowerCase();
    }
  };

  const getFileIcon = (fileName) => {
    const ext = getFileExtension(fileName);
    return getFontIconByExtension(ext);
  };

  return (
    <>
      {isLoading && <LoadPanel visible={true} />}
      <div ref={messageListRef} style={{ height }} className={`message-list ${className}`}>
        {messageslist.map((messageData) => (
          <div
            key={messageData.id}
            className={`message-item-wrapper mb-4 ${messageData.customerType === 'SPP_USER' ? 'client' : 'spp-user'}`}
          >
            <div className={'message-date'}>
              {moment(messageData.createdAt).format('DD.MM.YYYY HH:mm') + ' ' + getNameById(messageData.customerId)}
            </div>
            <div className={`message-wrapper`}>
              <div>{messageData.message}</div>
              <div className='message-files'>
                {messageData.files.map((file) => (
                  <Button
                    key={file.id}
                    onClick={() => handleDownloadFile(file.id)}
                    className='file-item'
                    icon={getFileIcon(file.filename)}
                    text={file.filename}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default MessagesList;
