import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import CasesNotesToPop from '../operations/CasesNotesToPop';
import ClientTransactionPop from '../clientsapproved/ClientTransactionPop';
import HTTP from '../../api/HTTP';

const ClientTransactions = (props) => {
  const { user, selClientid } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [clientTransactionsData, setClientTransactionsData] = useState([]);
  const [selectedTransactionid, setSelectedTransactionid] = useState(-1);
  const [selServiceid, setselServiceid] = useState(-1);

  const [signupClientDatalist, setSignupClientDatalist] = useState({});

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteEntryId, setNoteEntryId] = useState(-1);
  const [noteCaseEntryid, setNoteCaseEntryid] = useState(-1);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);
  const [contactClientMail, setContactClientMail] = useState('');

  const [showClientTransactionPop, setShowClientTransactionPop] = useState(false);
  const [caseSituationAction, setCaseSituationAction] = useState(false);

  const [selTransactionRecord, setSelTransactionRecord] = useState({});

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const dataArray = await HTTP.get(`/rep/clienttransactionsprovider/${selClientid}/${user.providerid}`);
        setClientTransactionsData(dataArray.data);
      } catch (err) {}
    };
    if (selClientid) {
      fetchTransactions();
    }
  }, [selClientid]);

  useEffect(() => {
    const fetchSignupClientData = async () => {
      try {
        const dataArray = await HTTP.get(`/client/clientsignupdata/${selClientid}`, 'GET');
        setSignupClientDatalist(dataArray.data[0]);
      } catch (err) {}
    };
    if (selClientid) {
      fetchSignupClientData();
    }
  }, [selClientid]);

  const stClientTransactionsData = new ArrayStore({
    key: 'transactionid',
    data: clientTransactionsData,
  });

  const dcClientTransactionsData = new DataSource({
    store: stClientTransactionsData,
  });

  const contactSelectedClient = () => {
    setContactClientid(signupClientDatalist.signupid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(signupClientDatalist.portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.signupid);
    setContactPortalid(signupClientDatalist.portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = () => {
    setContactClient(false);
    setContactClientid(signupClientDatalist.signupid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);
    await setNoteEntryId(-1);
    await setNoteCaseEntryid(-1);
    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
  };

  const showClientTransactionDialog = () => {
    setShowClientTransactionPop(true);
  };

  const hideClientTransactionDialog = async () => {
    await setShowClientTransactionPop(false);
  };

  return (
    <React.Fragment>
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={noteEntryId}
        caseEntryid={noteCaseEntryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        contactClientMail={contactClientMail}
        clientData={signupClientDatalist}
        caseSituationAction={caseSituationAction}
      />
      <ClientTransactionPop
        showPopup={showClientTransactionPop}
        hidePopup={hideClientTransactionDialog}
        user={user}
        selTransactionRecord={selTransactionRecord}
        clientid={selClientid}
      />

      <div id='client-users-transactions-box'>
        <div className='sd-header item'>
          <div className='dx-fieldset-header'>Transactions</div>
        </div>{' '}
        <ResponsiveBox>
          <Col ratio={1.5} />
          <Col ratio={9} />
          <Row ratio={1} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-1'>
              <p>Transaction Request</p>
              <p>Contact</p>
            </div>
            <div id='hm-btn-section-1'>
              <Button
                width={120}
                height={36}
                text='Merchant'
                type='default'
                stylingMode='outlined'
                onClick={showClientTransactionDialog}
              />
            </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div>
              <DataGrid
                height={'540px'}
                dataSource={dcClientTransactionsData}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                focusedRowEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                autoNavigateToFocusedRow={true}
                focusedRowKey={selectedTransactionid}
                onFocusedRowChanged={(e) => {
                  if (e.row) {
                    setSelectedTransactionid(e.row.key);
                    setselServiceid(e.row.data.serviceid);
                    setSelTransactionRecord(e.row.data);
                  }
                }}
              >
                <FilterRow visible={true} />
                <Column dataField='transactionid' visible={false}></Column>
                <Column dataField='providerid' visible={false}></Column>
                <Column dataField='clientid' visible={false}></Column>
                <Column dataField='transferdate' caption='Transfer Date' dataType='datetime' width={130}></Column>
                <Column dataField='servicename' caption='Service' visible={true} width={190} />
                <Column dataField='operation' caption='Operation' visible={true} width={80} />{' '}
                <Column dataField='assetname' caption='Asset' visible={true} width={80} />
                <Column dataField='assetamount' caption='Amount' visible={true} width={150} />
                <Column dataField='assetcalcunit' caption='Unit' visible={true} width={70} />
                <Column dataField='currency' caption='Currency' visible={true} width={60} />
                <Column dataField='currencyamount' caption='Amount' visible={true} width={80} />
                <Column dataField='provider_fee' caption='Fee' visible={true} width={70} />
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ClientTransactions;
