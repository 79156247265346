import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './ServiceDetails.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import ServicesEditFeatures from './ServiceDetailsFeatures';
import ServiceDetailsAccounts from './ServiceDetailsAccounts';
import ServiceDetailsCostRates from './ServiceDetailsCostRates';
import ServiceDetailsCustDocs from './ServiceDetailsCustDocs';
import ServiceDetailsTermsofUse from './ServiceDetailsTermsofUse';
import ServiceProvTransactions from './ServiceProvTransactions';

const PartnerServicesEditPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const selServiceId = user.service && user.service.selServiceId;
  const modeInsert = user.service && user.service.modeInsert;
  const history = useHistory();

  useEffect(() => {
    if (!user.service) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-services-box-line1-left'>
                <ServicesEditFeatures selServiceId={selServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-services-box-line1-right'>
                <ServiceDetailsAccounts selServiceId={selServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='service-details-box-sell-rates'>
                <ServiceDetailsCostRates selServiceId={selServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={2} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='service-details-box-transactions'>
                <ServiceProvTransactions selServiceId={selServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='service-details-box-line4-left'>
                <ServiceDetailsCustDocs selServiceId={selServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={3} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='partner-services-box-line4-right'>
                <ServiceDetailsTermsofUse selServiceId={selServiceId} modeInsert={modeInsert} user={user} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={4} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PartnerServicesEditPage;
