import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import './GlobalDefinitions.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow, MasterDetail } from 'devextreme-react/data-grid';

import BankAccountDetailGrid from '../../detailgrids/BankAccountDetailGrid';
import HTTP from '../../api/HTTP';

const BankAccountsTab = (props) => {
  const { user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [bankAccountList, setBankAccountList] = useState([]);
  const [sysServiceID, setSysServiceID] = useState(-1);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const dataArray = await HTTP.get(`/routing/providerbankaccounts/${user.providerid}`);
        setBankAccountList(dataArray.data);
      } catch (err) {}
    };
    fetchBankAccounts();
  }, []);

  const stBankData = new ArrayStore({
    key: ['serviceid', 'bankaccountid'],
    data: bankAccountList,
  });

  const dcBankAccountData = new DataSource({
    store: stBankData,
  });

  const openBankAccountEdit = () => {
    user.bankaccount = {
      systemServiceId: sysServiceID,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: '/bankaccountedit',
    });
  };

  const openBankAccountInsert = () => {
    user.bankaccount = {
      systemServiceId: -1,
      user: user,
      modeInsert: true,
    };
    history.push({
      pathname: '/bankaccountedit',
    });
  };

  return (
    <div id='settings-tou-box'>
      <div className='dx-fieldset-header'>Bank Accounts</div>

      <ResponsiveBox>
        <Col ratio={2} screen='md lg sm xs' />
        <Col ratio={8} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-0-1'>
            <Button
              width={100}
              height={36}
              text='Add'
              type='default'
              disabled={true}
              stylingMode='outlined'
              onClick={openBankAccountInsert}
            />
          </div>
          <div id='hm-btn-section-0-11'>
            <Button
              width={100}
              height={36}
              text='Open'
              type='success'
              disabled={true}
              stylingMode='outlined'
              onClick={openBankAccountEdit}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <DataGrid
            height={400}
            dataSource={dcBankAccountData}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            focusedRowEnabled={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            autoNavigateToFocusedRow={true}
            onFocusedRowChanged={(e) => {
              if (e.row) {
                console.log(e.row.key);
                setSysServiceID(e.row.key.bankaccountid);
              }
            }}
          >
            <FilterRow visible={false} />
            <Column dataField='bankaccountid' visible={false}></Column>
            <Column dataField='servicename' caption='Service' width={180} />
            <Column dataField='accountname' visible={true} caption='Account Name' width={270}></Column>
            <Column dataField='rec_name' caption='Account Owner' width={200} />
            <Column dataField='bankname' visible={true} caption='Bank' width={200}></Column>
            <Column dataField='currency' caption='Currency' width={80} />
            <Column dataField='cur_amount' caption='Current Amount' width={110} />
            <MasterDetail enabled={true} component={BankAccountDetailGrid} />
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default BankAccountsTab;
