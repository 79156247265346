import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';
import HTTP from '../../api/HTTP';

const CaseEditPop = (props) => {
  const {
    showPopup,
    hidePopup,
    user,
    entryid,
    openedToEdit,
    dstCaseType,
    dstCaseStates,
    dstStDevPrios,
    dstStCaseSituation,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const caseDataFormRef = useRef(null);
  const [showContactPopup, setShowContactPopup] = useState();
  const [caseData, setCaseData] = useState({});
  const [lpContacts, setLpContacts] = useState([]);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [caseDataModified, setCaseDataModified] = useState(false);

  useEffect(() => {
    const fetchCase = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/single/${entryid}`);
        setCaseData(dataArray.data[0]);
      } catch (err) {}
    };

    if (entryid && showPopup && openedToEdit === 2) {
      fetchCase();
    } else if (entryid && showPopup && openedToEdit === 1) {
      setCaseData({});
    }
  }, [entryid, showPopup]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const dataArray = await HTTP.get('/cases/clients');
        setLpContacts(dataArray.data);
      } catch (err) {}
    };
    if (showPopup) {
      fetchContacts();
    }
  }, [showPopup, reloadContacts]);

  const stContacts = new ArrayStore({
    key: 'signupid',
    data: lpContacts,
  });

  const caseEditData = {
    entryid: caseData.entryid || -1,
    case_entryid: caseData.case_entryid || -1,
    clientid: caseData.clientid || -1,
    case_type: caseData.case_type || 6,
    case_datetime: caseData.case_datetime || new Date(),
    subject: caseData.subject || '',
    case_priority: caseData.case_priority || 3,
    case_state: caseData.case_state || 1,
    case_content: caseData.case_content || '',
    case_situation: caseData.case_situation || -1,
    userid: caseData.portaluserid || user.id,
    username: caseData.portaluser || user.lastname + ' ' + user.firstName,
  };

  const saveCase = async (an_operation) => {
    try {
      await HTTP.post(
        '/cases/modcase',
        JSON.stringify({
          operation: an_operation,
          entryid: caseEditData.entryid,
          case_entryid: caseEditData.case_entryid,
          clientid: caseEditData.clientid,
          case_type: caseEditData.case_type,
          subject: caseEditData.subject,
          case_priority: caseEditData.case_priority,
          case_state: caseEditData.case_state,
          case_content: caseEditData.case_content,
          case_situation: caseEditData.case_situation,
          userid: caseEditData.userid,
          iscase: true,
          portalid: user.portalid,
          partnerid: user.partnerid,
          providerid: user.providerid,
          from_sender: user.portalname,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async (caseDataFormRef) => {
    const result = caseDataFormRef.validationGroup.validate();
    // console.log(result);
    if (result.isValid) {
      setCaseDataModified(true);
      saveCase(openedToEdit);
      hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  const hideContactPopup = useCallback(() => {
    setShowContactPopup(false);
    setReloadContacts(!reloadContacts);
  });

  return (
    <React.Fragment>
      <Popup
        title={'Case'}
        width={800}
        // height={640}
        ref={popupRef}
        visible={showPopup}
        resizeEnabled={true}
        showCloseButton={true}
        onHiding={() => {
          hidePopup(caseDataModified);
        }}
      >
        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.1} />
          <Col />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <Form
                id={'form'}
                formData={caseEditData}
                labelLocation={'left'}
                colCountByScreen={colCountByScreen}
                ref={caseDataFormRef}
                validationGroup='CasesData'
              >
                <Item itemType='group' colCount={3}>
                  <SimpleItem
                    colSpan={2}
                    dataField='case_situation'
                    editorType='dxSelectBox'
                    editorOptions={{
                      dataSource: dstStCaseSituation,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Situation' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='case_type'
                    editorType='dxSelectBox'
                    editorOptions={{
                      dataSource: dstCaseType,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Section' />
                  </SimpleItem>
                </Item>
                <EmptyItem />

                <Item itemType='group' colCount={3}>
                  <SimpleItem
                    dataField='clientid'
                    colSpan={2}
                    editorType='dxSelectBox'
                    isRequired={true}
                    editorOptions={{
                      dataSource: stContacts,
                      valueExpr: 'signupid',
                      displayExpr: 'clientname',
                      searchEnabled: true,
                    }}
                  >
                    <RequiredRule message='Please select a Contact' />
                    <Label text='Contact' />
                  </SimpleItem>
                  {/*
                  <SimpleItem
                    itemType='button'
                    colSpan={1}
                    horizontalAlignment='left'
                    buttonOptions={{
                      text: 'search',
                      type: 'normal',
                      width: '120',
                      height: '36',
                      onClick: () => {
                        setShowContactPopup(true);
                      },
                    }}
                  />
                  */}
                </Item>
                <EmptyItem />

                <Item itemType='group' colCount={1}>
                  <SimpleItem dataField='subject' isRequired={true}>
                    <RequiredRule message='Please enter a Subject' />
                    <Label text='Case' />
                  </SimpleItem>
                </Item>
                <EmptyItem />

                <Item itemType='group' colCount={1}>
                  <SimpleItem
                    dataField='case_content'
                    editorType='dxTextArea'
                    isRequired={true}
                    editorOptions={{ height: 210 }}
                  >
                    <RequiredRule message='Please enter Case Content' />
                    <Label text='Content' />
                  </SimpleItem>
                </Item>

                <EmptyItem />

                <Item itemType='group' colCount={3}>
                  <SimpleItem dataField='case_datetime' editorType='dxDateBox' editorOptions={{ type: 'datetime' }}>
                    <Label text='Date' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='case_priority'
                    editorType='dxSelectBox'
                    editorOptions={{
                      dataSource: dstStDevPrios,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Priotity' />
                  </SimpleItem>
                  <SimpleItem
                    dataField='case_state'
                    editorType='dxSelectBox'
                    editorOptions={{
                      dataSource: dstCaseStates,
                      valueExpr: 'lp_id',
                      displayExpr: 'lp_value',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='State' />
                  </SimpleItem>
                </Item>

                <EmptyItem />

                <Item itemType='group' colCount={4}>
                  <SimpleItem dataField='username' editorOptions={{ readOnly: true }}>
                    <Label text='User' />
                  </SimpleItem>
                </Item>
              </Form>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div id={'closebutton'}>
              <div style={{ flexDirection: 'row' }}>
                <Button
                  id='savebutton'
                  width={120}
                  height={40}
                  text='Save'
                  type='default'
                  stylingMode='contained'
                  validationGroup='CasesData'
                  useSubmitBehavior={true}
                  onClick={validateForm}
                />
                <Button
                  width={120}
                  height={40}
                  text='close'
                  type='normal'
                  stylingMode='contained'
                  onClick={() => {
                    hidePopup(false);
                  }}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </Popup>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CaseEditPop;
