import React, { useEffect, useRef, useState } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';
import { useHttpClient } from '../../hooks/http-hook';
import { SelectBox } from 'devextreme-react/select-box';
import ArrayStore from 'devextreme/data/array_store';
import { useAuth } from '../../contexts/auth';
import HTTP from '../../api/HTTP';

const AccountAddPopup = (props) => {
  const { showPopup, hidePopup, dialogTitle, serviceid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { user } = useAuth();
  const popupRef = useRef(null);

  const addAccountData = {
    serviceid: serviceid || -1,
    accountvalue: 'DE67 3445 2334 6699 0045 33',
  };

  const addAccountId = async () => {
    try {
      await HTTP.post(
        '/service/addserviceaccount',
        JSON.stringify({
          operation: 1,
          serviceid: addAccountData.serviceid || -1,
          accountvalue: addAccountData.accountvalue,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    hidePopup(true);
  }

  const executeAddAccount = () => {
    addAccountId();
    hidePopup(true);
  };

  return (
    <Popup
      title={dialogTitle}
      width={600}
      height={300}
      ref={popupRef}
      visible={showPopup}
      showCloseButton={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form id={'form'} formData={addAccountData} labelLocation={'left'} colCountByScreen={colCountByScreen}>
            <Item itemType='group' colCount={1}>
              <SimpleItem dataField='accountvalue'>
                <Label text='Account ID' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={50}
                text='Save'
                type='normal'
                stylingMode='contained'
                useSubmitBehavior={true}
                onClick={executeAddAccount}
              />
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default AccountAddPopup;
