import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import Button from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';

import CaseEditPop from './CaseEditPop';

import Form, { SimpleItem, GroupItem, Label, ButtonItem } from 'devextreme-react/form';

import { useClientData } from '../../hooks/cientdata-hook';
import HTTP from '../../api/HTTP';

const getscreenheight = () => {
  const anValue = window.innerHeight - 700;
  // console.log(5678, anValue);
  return anValue;
};

const CasesOneTab = (props) => {
  const {
    user,
    selPartnerPortal,
    passToParent,
    selnewskey,
    dataStCaseType,
    dataStCaseStates,
    dataStDevPrios,
    getReload,
    reloadGrid,
    dataStCaseSituation,
  } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { callClientData, clientUsesForex } = useClientData();

  const [casesDataList, setCasesDatalist] = useState([]);
  const [casesGridData, setCasesGridData] = useState([]);
  const [selEntryid, setSelEntryid] = useState(0);
  const [showCasePopup, setShowCasePopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [clientSearchText, setClientSearchText] = useState('');
  const [selClientid, setSelClientid] = useState();

  const casesOneGrid = useRef(null);
  const [compDay, setCompDay] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    if (selnewskey) {
      setSelEntryid(selnewskey.caseid);
      setSelClientid(selnewskey.clientid);
    }
  }, [selnewskey]);

  useEffect(() => {
    if (showCasePopup === false && clientSearchText.length === 0) {
      const interval = setInterval(async () => {
        try {
          const dataArray = await HTTP.get(`/cases/all/${selPartnerPortal}`);
          await setCasesDatalist(dataArray.data);
          await selClientid(dataArray.data[0].clientid);
        } catch (err) {}
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [selPartnerPortal]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/all/${selPartnerPortal}`);
        setCasesDatalist(dataArray.data);
        setSelClientid(dataArray.data[0].clientid);
      } catch (err) {}
    };
    if (selPartnerPortal) {
      fetchCases();
    }
  }, [reloadGrid, selPartnerPortal]);

  const fetchSearchCase = async (field1, field2, field3) => {
    try {
      await HTTP.post(
        '/cases/casesearch',
        JSON.stringify({
          field1: '%' + field1 + '%',
          field2: '%' + field2 + '%',
          field3: '%' + field3 + '%',
          portalid: user.portalid,
        })
      ).then((response) => {
        // console.log(data)
        setCasesDatalist(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const navigateToCasePage = () => {
    setOpenedType(2);
    setShowCasePopup(true);
  };

  const navigateToAddNewCasePage = () => {
    setOpenedType(1);
    setShowCasePopup(true);
  };

  const cleanfield = (anfield) => {
    let resultfield = anfield.replace('+', '');
    resultfield = resultfield.replace('-', '');
    resultfield = resultfield.replace('(', '');
    resultfield = resultfield.replace(')', '');
    resultfield = resultfield.replace(';', '');
    resultfield = resultfield.replace(',', '');
    return resultfield;
  };

  const doSearch = (anSearchText) => {
    let field1 = anSearchText.split(' ')[0] ? anSearchText.split(' ')[0] : '';
    let field2 = anSearchText.split(' ')[1] ? anSearchText.split(' ')[1] : '';
    let field3 = anSearchText.split(' ')[2] ? anSearchText.split(' ')[2] : '';

    field1 = cleanfield(field1);
    field2 = cleanfield(field2);
    field3 = cleanfield(field3);

    fetchSearchCase(field1, field2, field3);
  };

  const renderGridCell = (cellData) => {
    const gDay = cellData.value.toISOString().split('T')[0];
    // console.log(111, compDay, gDay);
    if (compDay === gDay) {
      return <div style={{ color: 'blue' }}>{cellData.text}</div>;
    } else {
      return <div style={{ color: 'default' }}>{cellData.text}</div>;
    }
  };

  const hideCasePopup = useCallback((doContactReload) => {
    setShowCasePopup(false);
    console.log(doContactReload);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
      getReload(true);
    }
  });

  const handleSubmit = (e) => {
    const searchtext = e.target.elements.searchtext.value;
    // console.log(1234, searchtext);
    setClientSearchText(searchtext);
    doSearch(searchtext);
    e.preventDefault();
  };

  const clientOpenClick = async () => {
    const clientData = await callClientData(user, selClientid);
    const clientUsesFx = await clientUsesForex(user, selClientid);

    user.client = {
      selClientid: selClientid,
      selClientData: clientData,
      selClientUsesForex: clientUsesFx,
    };

    history.push({
      pathname: '/clientdata',
    });
  };

  return (
    <React.Fragment>
      <CaseEditPop
        showPopup={showCasePopup}
        hidePopup={hideCasePopup}
        user={user}
        entryid={selEntryid}
        openedToEdit={openedType}
        dstCaseType={dataStCaseType}
        dstCaseStates={dataStCaseStates}
        dstStDevPrios={dataStDevPrios}
        dstStCaseSituation={dataStCaseSituation}
      />
      <ResponsiveBox>
        <Col ratio={0.4} screen='md lg sm' />
        <Col ratio={2.3} />
        <Row ratio={0.2} />
        <Row ratio={3} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div className='rb-header item'>
            <div className='dx-fieldset-header' id='cap_item'>
              Clients
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm' row={0} col={1} colspan={2} />

          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={2} />
            <Col ratio={2} />

            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <form
                action='search'
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div id='op-find-client-section'>
                  <Form id='client-search-section' labelLocation='left'>
                    <GroupItem cssClass='first-group' colCount={3}>
                      <SimpleItem dataField='searchtext' colSpan={3}>
                        <Label text='find' />
                      </SimpleItem>
                    </GroupItem>
                  </Form>
                </div>
              </form>
            </Item>
          </ResponsiveBox>
        </Item>

        <Item>
          <Location screen='md lg sm' row={1} col={0} />
          <div id='hm-btn-section-1'>
            <p>Client</p>
            <Button
              width={120}
              height={36}
              text='Open'
              type='default'
              stylingMode='outlined'
              onClick={clientOpenClick}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={1} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='casesGrid'
              height={getscreenheight}
              ref={casesOneGrid}
              dataSource={casesDataList}
              keyExpr='entryid'
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selEntryid.toString()}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  if (e.row.key !== selEntryid) {
                    setSelEntryid(e.row.key);
                    setCasesGridData(e.row.data);
                    setSelClientid(e.row.data.clientid);
                    passToParent(e.row.key, e.row.data.subject, e.row.data.case_content, e.row.data.clientid);
                  }
                }
              }}
              onRowDblClick={() => {
                navigateToCasePage();
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='entryid' visible={false} />
              <Column dataField='portaluserid' visible={false} />
              <Column dataField='portalid' visible={false} />
              <Column
                dataField='lastnote_datetime'
                caption='Last Note/Chat'
                dataType='datetime'
                width={130}
                cellRender={renderGridCell}
              ></Column>
              <Column dataField='clientname' caption='Contact' width={200}></Column>
              <Column dataField='subject' caption='Subject'></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesOneTab;
