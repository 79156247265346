import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './serviceproviderservices.css';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';
import AccountAddPopup from './AccountAddPopup';
import HTTP from '../../api/HTTP';

const ServicesAccounts = (props) => {
  const { selServiceId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [accountsData, setAccountsData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const dataArray = await HTTP.get(`/service/serviceaccounts/${selServiceId}`);
        setAccountsData(dataArray.data);
      } catch (err) {}
    };

    if (selServiceId) {
      fetchAccounts();
    }
  }, [selServiceId, dataReload]);

  const stAccountsData = new ArrayStore({
    key: ['accountid'],
    data: accountsData,
  });

  const dcAccountsData = new DataSource({
    store: stAccountsData,
  });

  function navigateToHome() {
    history.goBack();
  }

  const showAccountAddPopup = () => {
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setDataReload(!dataReload);
  };

  return (
    <React.Fragment>
      <AccountAddPopup
        showPopup={showPopup}
        hidePopup={hidePopup}
        serviceid={selServiceId}
        dialogTitle={'Add Account ID'}
      />
      <div id='service-edit-form-edit'>
        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.2} />
          <Col ratio={0.4} screen='md lg sm xs' />
          <Col ratio={3} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='svpro-hm-btn-section-1'>
              <Button width={120} height={50} text='Add' type='default' onClick={showAccountAddPopup} />
            </div>
            <div id='svpro-hm-btn-section-11'>
              <Button
                width={120}
                height={50}
                text='Import'
                type='success'
                // onClick={openServiceFeeEdit}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />

            <DataGrid
              id='service_provider-accounts-grid'
              dataSource={dcAccountsData}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              columnAutoWidth={true}
              focusedRowEnabled={true}
            >
              <Column dataField='serviceid' visible={false}></Column>
              <Column dataField='accountid' visible={false}></Column>
              <Column dataField='categoryid' visible={false}></Column>
              <Column dataField='accountvalue' caption='Account ID' width='300'></Column>

              <Column dataField='clientid' caption='Client ID' visible={false} width='200px'></Column>
              <Column dataField='cl_lastname' caption='Last Name' width='150px'></Column>
              <Column dataField='cl_firstname' caption='First Name' width='100px'></Column>
              <Column dataField='clientassigned' caption='Assigned' dataType='date' width='200px'></Column>
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default ServicesAccounts;
