import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './serviceproviderservices.css';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';

import DataGrid, { Column, Editing } from 'devextreme-react/data-grid';
import HTTP from '../../api/HTTP';

const ServicesEditFeatures = (props) => {
  const { selServiceId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [serviceFeaturesData, setServiceFeaturesData] = useState([]);

  useEffect(() => {
    const fetchServiceFeatures = async () => {
      try {
        const dataArray = await HTTP.get(`/service/systemservicefeatures/${selServiceId}`);
        setServiceFeaturesData(dataArray.data);
      } catch (err) {}
    };
    if (selServiceId) {
      fetchServiceFeatures();
    }
  }, [selServiceId]);

  const stServiceFeaturesData = new ArrayStore({
    key: ['serviceid', 'group_assetid', 'featureid'],
    data: serviceFeaturesData,
  });

  const dcServiceFeaturesData = new DataSource({
    store: stServiceFeaturesData,
  });

  const saveFeatureUse = async (anFeatureUseData) => {
    try {
      HTTP.post(
        '/service/modsystemservicefeature',
        JSON.stringify({
          operation: 2,
          serviceid: anFeatureUseData.serviceid,
          featureid: anFeatureUseData.featureid,
          usefeature: anFeatureUseData.usefeature,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.2} />

        <Col />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='provider-services-tab-features'>
            <DataGrid
              dataSource={dcServiceFeaturesData}
              keyExpr={'servicefeature_id'}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              columnAutoWidth={false}
              focusedRowEnabled={true}
              onSaved={(e) => {
                if (e.changes[0]) {
                  saveFeatureUse(e.changes[0].data);
                }
              }}
            >
              <Column dataField='servicefeature_id' visible={false}></Column>
              <Column dataField='category_id' visible={false}></Column>
              <Column dataField='buysellasset' visible={false}></Column>
              <Column dataField='sendreceiveasset' visible={false}></Column>
              <Column dataField='feature' caption='Feature' width='200'></Column>
              <Column dataField='groupasset' caption='Group' width='100'></Column>
              <Column dataField='main_feature' dataType='boolean' caption='Service' width='90'></Column>
              <Column dataField='account_feature' dataType='boolean' caption='Account' width='90'></Column>
              <Column dataField='buy' dataType='boolean' caption='Buy' width='60'></Column>
              <Column dataField='sell' dataType='boolean' caption='Sell' width='60'></Column>
              <Column dataField='list' dataType='boolean' caption='List' width='60'></Column>
              <Column dataField='send' dataType='boolean' caption='Send' width='60'></Column>
              <Column dataField='receive' dataType='boolean' caption='Receive' width='70'></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServicesEditFeatures;
