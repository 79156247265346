import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import { useAuth } from '../../contexts/auth';

import './serviceproviderservices.css';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { Button } from 'devextreme-react/button';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow, MasterDetail } from 'devextreme-react/data-grid';

import { Tabs, MultiView } from 'devextreme-react';

import ServicesAccounts from './ServicesAccounts';
import ServicesEditFeatures from './ServicesEditFeatures';
import ServicesEditCostRates from './ServicesEditCostRates';
import ServiceCustomerDocs from './ServiceCustomerDocs';
import ServicesEditTermsofUse from './ServicesEditTermsofUse';
import HTTP from '../../api/HTTP';

const getScreenGridHeight = () => {
  const anValue = window.innerHeight - 770;
  return anValue;
};

const ServicesPage = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [provServiceData, setProvServiceData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selServiceId, setSelServiceId] = useState(-1);

  useEffect(() => {
    const fetchProviderServices = async () => {
      try {
        const dataArray = await HTTP.get(`/provider/providerservices/${user.providerid}`);
        setProvServiceData(dataArray.data);
      } catch (err) {}
    };

    fetchProviderServices();
  }, []);

  const stprovServiceData = new ArrayStore({
    key: 'serviceid',
    data: provServiceData,
  });

  const dcprovServiceData = new DataSource({
    store: stprovServiceData,
  });

  function navigateToHome() {
    history.goBack();
  }

  const openServiceDetails = () => {
    user.service = {
      selServiceId: selServiceId,
      user: user,
      modeInsert: false,
    };
    history.push({
      pathname: '/servicedetails',
    });
  };

  return (
    <div className={'content-block'}>
      <div className={'dx-card wide-card'}>
        <div className='dx-fieldset-header' id='cap_item'>
          Services
        </div>
        <ResponsiveBox>
          <Row ratio={2} />
          <Row ratio={2} />
          <Col ratio={0.3} />
          <Col ratio={3} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-1'>
              <Button
                width={120}
                height={36}
                text='Open'
                type='success'
                stylingMode='outlined'
                onClick={openServiceDetails}
              />
            </div>
          </Item>

          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />

            <DataGrid
              id='serviceprovideredit-services-tab-grid'
              width={500}
              height={getScreenGridHeight}
              dataSource={dcprovServiceData}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={false}
              allowColumnResizing={false}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={false}
              autoNavigateToFocusedRow={true}
              onFocusedRowChanged={(e) => {
                setSelServiceId(e.row.key);
              }}
            >
              <Column dataField='serviceid' visible={false} />
              <Column dataField='categoryid' visible={false} />
              <Column dataField='providerid' visible={false} />
              <Column dataField='servicename' caption='Services' width='250px' />
              <Column dataField='category_name' caption='Category' width='150px' />
              <Column dataField='currency' caption='Currency' width='80px' />
            </DataGrid>
            <Tabs
              id='service-tab'
              selectedIndex={tabIndex}
              width='99%'
              // height='20px'
              visible={true}
              onItemClick={(e) => {
                setTabIndex(e.itemIndex);
              }}
            >
              <Item text='Features' />
              <Item text='Accounts' />
              <Item text='Cost Rates' />
              <Item text='Required Docs' />
              <Item text='Terms of Use' />
            </Tabs>
            <MultiView height={560} animationEnabled={false} selectedIndex={tabIndex}>
              <Item>
                <ServicesEditFeatures selServiceId={selServiceId} user={user} />
              </Item>
              <Item title='Customer Accounts'>
                <ServicesAccounts selServiceId={selServiceId} user={user} />
              </Item>
              <Item title='Cost Rates'>
                <ServicesEditCostRates selServiceId={selServiceId} user={user} />
              </Item>
              <Item title='Req. Customer Docs'>
                <ServiceCustomerDocs selServiceId={selServiceId} user={user} />
              </Item>
              <Item title='Terms of Use'>
                <ServicesEditTermsofUse selServiceId={selServiceId} user={user} />
              </Item>
            </MultiView>
          </Item>
        </ResponsiveBox>
      </div>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ServicesPage;
