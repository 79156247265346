import React, { useState, useEffect, useRef, useCallback } from 'react';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import Button from 'devextreme-react/button';

import './Reporting.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import PivotGrid, { FieldChooser, FieldPanel } from 'devextreme-react/pivot-grid';
import { SelectBox } from 'devextreme-react';
import HTTP from '../../api/HTTP';

const calendarviews = ['day', 'week', 'workWeek', 'month'];
// const currentDate = new Date().today();

const ReportingTab = (props) => {
  const user = props.user;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [repTransactionsData, setRepTransactionsData] = useState([]);

  useEffect(() => {
    const fetchRetTransactions = async () => {
      try {
        const dataArray = await HTTP.get(`/rep/reptransactionsprovider/${user.providerid}`);
        setRepTransactionsData(dataArray.data);
      } catch (err) {}
    };
    fetchRetTransactions();
  }, []);

  const stTransactionsData = new ArrayStore({
    key: 'transactionid',
    data: repTransactionsData,
  });

  const dcTransactionsData = new PivotGridDataSource({
    fields: [
      {
        caption: 'Merchant',
        width: 120,
        dataField: 'merchant',
        area: 'row',
      },
      {
        caption: 'Client',
        dataField: 'client',
        width: 150,
        area: 'row',
        selector: function (data) {
          return `${data.client} (${data.asset}) (${data.buysell})`;
        },
      },
      {
        dataField: 'day',
        dataType: 'date',
        area: 'column',
      },
      {
        caption: 'Transactions',
        dataField: 'currencyamount',
        dataType: 'number',
        summaryType: 'sum',
        format: {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'code',
          maximumFractionDigits: 2,
        },
        area: 'data',
      },
      {
        caption: 'Client Fees',
        dataField: 'client_fee',
        dataType: 'number',
        summaryType: 'sum',
        format: { type: 'fixedPoint', precision: 2 },
        area: 'data',
      },
      {
        caption: 'Merchant Fees',
        dataField: 'partner_fee',
        dataType: 'number',
        summaryType: 'sum',
        format: { type: 'fixedPoint', precision: 2 },
        area: 'data',
      },
      {
        caption: 'Provider Fees',
        dataField: 'provider_fee',
        dataType: 'number',
        summaryType: 'sum',
        format: { type: 'fixedPoint', precision: 2 },
        area: 'data',
      },
    ],
    store: stTransactionsData,
  });

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Col />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <PivotGrid
            id='rep-pivot-grid'
            dataSource={dcTransactionsData}
            allowSortingBySummary={true}
            allowSorting={true}
            allowFiltering={true}
            showBorders={true}
            height='100%'
            width='98%'
            //   onContextMenuPreparing={this.onContextMenuPreparing}
          >
            <FieldPanel
              //   showColumnFields={this.state.showColumnFields}
              //   showDataFields={this.state.showDataFields}
              //   showFilterFields={this.state.showFilterFields}
              //   showRowFields={this.state.showRowFields}
              allowFieldDragging={true}
              visible={true}
            />
            <FieldChooser height={500} />
          </PivotGrid>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ReportingTab;
