import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  ServiceProviderPage,
  ServicesPage,
  ReportingPage,
  BankAccountsEdit,
  ServiceFeeEdit,
  PartnerServiceFeesEdit,
  ProductServiceFeesEdit,
  TransactionsPage,
  ClientsApprovedPage,
  OperationsPage,
  OmCaseNotePage,
  ClientPage,
  ServiceDetailsPage,
  GlobalDefinitionsPage,
  ForexPage,
} from './pages';

const routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/serviceprovider',
    component: ServiceProviderPage,
  },
  {
    path: '/services',
    component: ServicesPage,
  },
  {
    path: '/reporting',
    component: ReportingPage,
  },
  {
    path: '/bankaccountedit',
    component: BankAccountsEdit,
  },
  {
    path: '/transactions',
    component: TransactionsPage,
  },
  {
    path: '/clients',
    component: ClientsApprovedPage,
  },
  { path: '/operations', component: OperationsPage },
  { path: '/casenote', component: OmCaseNotePage },
  { path: '/clientdata', component: ClientPage },
  { path: '/servicedetails', component: ServiceDetailsPage },
  { path: '/globaldefinitions', component: GlobalDefinitionsPage },
  { path: '/forex', component: ForexPage },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
