import React, { useRef, useState, useEffect } from 'react';
import './GlobalDefinitions.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import Box from 'devextreme-react/box';
import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';
import HTTP from '../../api/HTTP';

const ServiceRegionsTab = (props) => {
  const { user } = props;
  const [regionsDatalist, setRegionsDatalist] = useState([]);
  const [regionGridData, setRegionGridData] = useState({});
  const [departmentDataMod, setDeparmentDataMod] = useState(false);
  const [regionCountriesDatalist, setRegionCountriesDatalist] = useState([]);
  const [countryGridData, setCountryGridData] = useState({});
  const [lookupCountriesDatalist, setLookupCountriesDatalist] = useState([]);
  const [regionCountryDataMod, setRegionCountryDataMod] = useState(false);
  const [lpRisklevels, setLpRisklevels] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const regionData = {
    regionname: regionGridData && regionGridData.regionname,
    regionid: regionGridData && regionGridData.regionid,
    risklevel: regionGridData && regionGridData.risklevel,
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const dataArray = await HTTP.get(`/provider/getproviderregions/${user.providerid}`);
        setRegionsDatalist(dataArray.data);
      } catch (err) {}
    };
    if (user) {
      fetchRegions();
    }
  }, [departmentDataMod, user]);

  const stRegionsData = new ArrayStore({
    key: 'regionid',
    data: regionsDatalist,
  });

  useEffect(() => {
    const fetchRisklevels = async () => {
      try {
        const dataArray = await HTTP.get('/base/getlookupvalues/33');
        setLpRisklevels(dataArray.data);
      } catch (err) {}
    };
    fetchRisklevels();
  }, []);

  useEffect(() => {
    const fetchLookupCountries = async () => {
      try {
        const dataArray = await HTTP.get('/base/countries');
        setLookupCountriesDatalist(dataArray.data);
      } catch (err) {}
    };
    fetchLookupCountries();
  }, [departmentDataMod]);

  const stRisklevels = new ArrayStore({
    key: 'lp_id',
    data: lpRisklevels,
  });

  const stLookupCountriesData = new ArrayStore({
    key: 'countryid',
    data: lookupCountriesDatalist,
  });

  const modPartnerRegions = async (modify) => {
    try {
      await HTTP.post(
        '/partner/modregion',
        JSON.stringify({
          regionid: regionData.regionid,
          partnerid: -1,
          providerid: user.providerid,
          risklevel: regionData.risklevel,
          regionname: regionData.regionname,
          operation: modify,
        })
      );
      setDeparmentDataMod(!departmentDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchRegionCountries = async () => {
      try {
        const dataArray = await HTTP.get(`/service/regioncountries/${regionData.regionid}`);
        setRegionCountriesDatalist(dataArray.data);
      } catch (err) {}
    };
    if (regionData.regionid) {
      fetchRegionCountries();
    } else setRegionCountriesDatalist([]);
  }, [regionData.regionid, regionCountryDataMod]);

  const stRegionCountriesData = new ArrayStore({
    key: 'countryid',
    data: regionCountriesDatalist,
  });

  const regionCountryData = {
    regionid: regionGridData && regionGridData.regionid,
    countryid: countryGridData && countryGridData.countryid,
    countryname: countryGridData && countryGridData.countryname,
  };

  const modRegionCountries = async (modify) => {
    try {
      await HTTP.post(
        '/service/regioncountries',
        JSON.stringify({
          regionid: regionCountryData.regionid,
          countryid: regionCountryData.countryid,
          operation: modify,
        })
      );
      setRegionCountryDataMod(!regionCountryDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id='setting-regions-box'>
      <div className='sd-header item'>
        <div className='dx-fieldset-header'>Regions of Availability</div>
      </div>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={4} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='partner-companytab-box'>
            <Form id={'form'} formData={regionData} labelLocation={'left'}>
              <Item itemType='group' colCount={10}>
                <SimpleItem dataField='regionname' colSpan={6}>
                  <Label location='left' text='Region' />
                </SimpleItem>
                <SimpleItem
                  dataField='risklevel'
                  colSpan={4}
                  editorType='dxSelectBox'
                  name='risklevel_listbox'
                  editorOptions={{
                    dataSource: stRisklevels,
                    valueExpr: 'lp_id',
                    displayExpr: 'lp_value',
                    searchEnabled: true,
                  }}
                >
                  <Label text='Risk' />
                </SimpleItem>
              </Item>
            </Form>

            <Button
              id='companytab-button'
              colSpan={2}
              width={90}
              height={'36px'}
              text='Add'
              type='default'
              stylingMode='outlined'
              onClick={() => {
                modPartnerRegions(1);
              }}
            />

            <Button
              id='companytab-button'
              colSpan={2}
              width={90}
              height={'36px'}
              text='Mod'
              type='success'
              stylingMode='outlined'
              onClick={() => {
                modPartnerRegions(2);
              }}
            />

            <Button
              id='companytab-button'
              colSpan={2}
              width={90}
              height={'36px'}
              text='Rem'
              type='danger'
              stylingMode='outlined'
              onClick={() => {
                modPartnerRegions(3);
              }}
            />
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <ResponsiveBox>
            <Row />
            <Col ratio={4} />
            <Col ratio={5} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <DataGrid
                id='settings-partner-regions-one-grid'
                height={300}
                dataSource={stRegionsData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={false}
                onFocusedRowChanged={(e) => {
                  setRegionGridData(e.row.data);
                }}
              >
                <Column dataField='regionid' visible={false}></Column>
                <Column dataField='risklevel' visible={false}></Column>
                <Column dataField='regionname' caption='Region Name'></Column>
                <Column dataField='riskleveltxt' caption='Risk Level'></Column>
              </DataGrid>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <Form id='global-region-tab-countries' formData={regionCountryData} labelLocation={'left'}>
                <Item itemType='group' colCount={12}>
                  <SimpleItem
                    dataField='countryid'
                    editorType='dxSelectBox'
                    colSpan={6}
                    name='country_listbox'
                    editorOptions={{
                      dataSource: stLookupCountriesData,
                      valueExpr: 'countryid',
                      displayExpr: 'name_en',
                      searchEnabled: true,
                    }}
                  >
                    <Label text='Country' visible={false} />
                  </SimpleItem>

                  <ButtonItem
                    colSpan={3}
                    buttonOptions={{
                      text: 'Add',
                      width: 100,
                      height: 36,
                      type: 'default',
                      stylingMode: 'outlined',
                      onClick: () => {
                        modRegionCountries(1);
                      },
                    }}
                  />
                  <ButtonItem
                    colSpan={3}
                    buttonOptions={{
                      text: 'Rem',
                      width: 100,
                      height: 36,
                      type: 'danger',
                      stylingMode: 'outlined',
                      onClick: () => {
                        modRegionCountries(3);
                      },
                    }}
                  />
                </Item>

                <Item>
                  <DataGrid
                    id='partner-regions-tab-countries-grid'
                    height={240}
                    dataSource={stRegionCountriesData}
                    showRowLines={true}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    defaultFocusedRowIndex={0}
                    focusedRowEnabled={true}
                    showColumnHeaders={false}
                    onFocusedRowChanged={(e) => {
                      setCountryGridData(e.row.data);
                    }}
                  >
                    <Column dataField='regionid' visible={false} />
                    <Column dataField='countryid' visible={false} />
                    <Column dataField='countryname' visible={true} />
                  </DataGrid>
                </Item>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
      </ResponsiveBox>
    </div>
  );
};

export default ServiceRegionsTab;
