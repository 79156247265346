import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import '../OmCaseNote.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import ArrayStore from 'devextreme/data/array_store';

import { Form, SimpleItem, EmptyItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import HTTP from '../../../api/HTTP';

const CaseNote = (props) => {
  const { user, caseNoteData, reloadInquiryData, showDocuments, showChat, showDocSection, showChatSection } = props;
  const [lpInquiryState, setLpInquiryState] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchInquiryState = async () => {
      const dataArray = await HTTP.get('/base/getlookupvalues/47');
      setLpInquiryState(dataArray.data);
    };

    fetchInquiryState();
  }, []);

  const stInquiryState = new ArrayStore({
    key: 'lp_id',
    data: lpInquiryState,
  });

  const loadedInquiryData = {
    entryid: caseNoteData && parseInt(caseNoteData.entryid),
    subject: caseNoteData && caseNoteData.subject,
    case_content: caseNoteData && caseNoteData.case_content,
  };

  const createDocRequest = async () => {
    try {
      await HTTP.post(
        '/cases/createdocrequest',
        JSON.stringify({
          entryid: caseNoteData.entryid,
        })
      );
      reloadInquiryData(true);
    } catch (err) {
      console.log(err);
    }
  };

  const createChat = async () => {
    try {
      await HTTP.post(
        '/cases/createchat',
        JSON.stringify({
          entryid: caseNoteData.entryid,
        })
      );
      reloadInquiryData(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div id='case-note-from'>
        <table>
          <tbody>
            <tr>
              <td width='60px'>From:</td>
              <td width='100px'>
                <b>{caseNoteData.from_sender}</b>
              </td>
              <td width='60px'>to:</td>
              <td width='100px'>
                <b>{caseNoteData.to_receiver}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Form id='note-req-form' formData={loadedInquiryData} labelLocation={'left'} colCountByScreen={colCountByScreen}>
        <Item itemType='group' colCount={1}>
          <SimpleItem dataField='subject' editorOptions={{ stylingMode: 'outlined' }}>
            <Label text='Subject' visible={true} />
          </SimpleItem>
          <SimpleItem
            dataField='case_content'
            editorType='dxTextArea'
            editorOptions={{
              height: 300,
              stylingMode: 'outlined',
              readOnly: false,
            }}
          >
            <Label text='Content' visible={true} />
          </SimpleItem>
        </Item>
        <EmptyItem />
      </Form>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CaseNote;
