import React, { useEffect, useState } from 'react';
import DataGrid, { Scrolling, Column, FilterRow } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import HTTP from '../../api/HTTP';
import { useClientData } from '../../hooks/cientdata-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

const getActionGridHeight = () => {
  const anValue = Math.floor((window.innerHeight - 150) * 0.4);
  return anValue;
};

const getNewsGridHeight = () => {
  const anValue = Math.floor((window.innerHeight - 150) * 0.6);
  return anValue;
};

const CasesNews = (props) => {
  const { user, selPartnerPortal, retrieveNewsKey, reloadNewsGrid, reloadNewsGridCondition } = props;
  const history = useHistory();
  const { callClientData, clientUsesForex } = useClientData();
  const [casesNewsData, setCasesNewsData] = useState([]);
  const [pendingActivitiesData, setPendingActivitiesData] = useState([]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const dataArray = await HTTP.get(`/cases/casesnews/${selPartnerPortal}`);
        setCasesNewsData(dataArray.data);
        const response = await HTTP.get(`/base/crm-pending-activities/${selPartnerPortal}`);
        setPendingActivitiesData(response.data);
      } catch (err) {}
    }, 15000);
    return () => clearInterval(interval);
  }, [selPartnerPortal]);

  useEffect(() => {
    const fetchCasesNews = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/casesnews/${selPartnerPortal}`);
        setCasesNewsData(dataArray.data);

        // await delay(50);

        if (reloadNewsGrid === true) {
          await reloadNewsGridCondition(false);
        }
      } catch (err) {}
    };

    const fetchPendingActivities = async () => {
      try {
        const dataArray = await HTTP.get(`/base/crm-pending-activities/${selPartnerPortal}`);
        setPendingActivitiesData(dataArray.data);
      } catch (err) {}
    };

    if (selPartnerPortal) {
      fetchPendingActivities();
      fetchCasesNews();
    }
  }, [reloadNewsGrid, selPartnerPortal]);

  const stCasesNews = new ArrayStore({
    key: 'entryid',
    data: casesNewsData,
  });

  const dcCasesNews = new DataSource({
    store: stCasesNews,
  });

  const stPendingActivitiesData = new ArrayStore({
    key: 'crm_activity_no',
    data: pendingActivitiesData,
  });

  const clientOpenClick = async (anClientid) => {
    const clientData = await callClientData(user, anClientid);
    const clientUsesFx = await clientUsesForex(user, anClientid);

    user.client = {
      selClientid: anClientid,
      selClientData: clientData,
      selClientUsesForex: clientUsesFx,
    };

    history.push({
      pathname: '/clientdata',
    });
  };

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={0.4} />
        <Row ratio={0.6} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <DataGrid
            id='cases-news-grid'
            height={getActionGridHeight}
            dataSource={stPendingActivitiesData}
            defaultFocusedRowIndex={0}
            showBorders={true}
            focusedRowEnabled={true}
            showRowLines={true}
            wordWrapEnabled={true}
            onFocusedRowChanged={(e) => {
              if (e.row) {
                // console.log(e.row.data.clientid);
                retrieveNewsKey(e.row.data.case_entryid, e.row.data.clientid);
              }
            }}
            onRowDblClick={(e) => {
              clientOpenClick(e.data.clientid);
            }}
          >
            <Column dataField='crm_activity_no' visible={false} />
            <Column dataField='clientid' visible={false} />
            <Column dataField='case_entryid' visible={false} />
            <Column dataField='client' caption='Client' width={120} />
            <Column dataField='activityaction' caption='Required Action' encodeHtml={false} />
            <Column dataField='active_since' caption='Since' dataType='datetime' width={120} />
          </DataGrid>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <DataGrid
            id='cases-news-grid'
            height={getNewsGridHeight}
            dataSource={dcCasesNews}
            defaultFocusedRowIndex={0}
            showBorders={true}
            focusedRowEnabled={true}
            showRowLines={true}
            wordWrapEnabled={true}
            onFocusedRowChanged={(e) => {
              if (e.row) {
                retrieveNewsKey(e.row.data.case_entryid, e.row.data.clientid);
              }
            }}
          >
            <Column dataField='entryid' visible={false}></Column>
            <Column dataField='case_entryid' visible={false}></Column>
            <Column dataField='newsdata' caption='Latest Notes' encodeHtml={false} dataType='string'></Column>
          </DataGrid>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesNews;
