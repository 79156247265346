import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';

import './ClientsApprovedPage.css';

import { Form, SimpleItem, EmptyItem, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Tabs, MultiView, SelectBox } from 'devextreme-react';
import { useAuth } from '../../contexts/auth';
import { Label } from 'devextreme-react/data-grid';

import ClientsApprovedOneTab from './ClientsApprovedOneTab';
import ClientsApprovedDetails from './ClientsApprovedDetails';
import ClientsApprovedRiskMatrix from './ClientsApprovedRiskMatrix';
import ClientsApprovedKYC from './ClientsApprovedKYC';
import ClientsApprovedProduct from './ClientsApprovedProduct';
import ClientsApprovedTransactions from './ClientsApprovedTransactions';
import ClientsApprovedAccess from './ClientsApprovedAccess';
import ClientsApprovedAccountUsers from './ClientsApprovedAccountUsers';
import ClientsinSignupGeolocation from './ClientsinSignupGeolocation';

const ClientsApprovedPage = () => {
  const { user } = useAuth();
  const [selectedKey, setSelectedKey] = useState();
  const [partnerid, setPartnerid] = useState();
  const [productid, setProductid] = useState();
  const [accountType, setAccountType] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [reloadNewsGrid, setReloadNewsGrid] = useState(false);

  const getPersonid = useCallback((fPersonid, fPartnerid, fProductid, fAccountType) => {
    setSelectedKey(fPersonid);
    setPartnerid(fPartnerid);
    setProductid(fProductid);
    setAccountType(fAccountType);
  });

  const reloadNewsGridCondition = async (anValue) => {
    await setReloadNewsGrid(anValue);
  };

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <div id='clients-page-border'>
            <ResponsiveBox>
              <Row />
              <Col />

              <Item>
                <Location screen='md lg sm xs' row={0} col={0} />
                <div className='operation-page-content'>
                  <div className='operation-page-content-container'>
                    <ResponsiveBox>
                      <Row ratio={1} />
                      <Row ratio={1} />
                      <Col ratio={1} />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                        <ClientsApprovedOneTab passToParent={getPersonid} user={user} />
                        <div style={{ flexDirection: 'column' }}>
                          <div id='client-bottom-half'>
                            <Tabs
                              selectedIndex={tabIndex}
                              width='100%'
                              // height='20px'
                              visible={true}
                              onItemClick={(e) => {
                                setTabIndex(e.itemIndex);
                              }}
                            >
                              <Item text='Client' />
                              <Item text='Account' />
                              {user.client_multi_account_users === true && <Item text='User' />}
                              <Item text='Risk Matrix' />
                              <Item text='KYC' />
                              <Item text='Transactions' />
                              {user.client_account_user_security === true && <Item text='Geolocation' />}
                            </Tabs>
                            <MultiView height={420} animationEnabled={false} selectedIndex={tabIndex}>
                              <Item title='Client Details'>
                                <ClientsApprovedDetails
                                  selectedKey={selectedKey}
                                  user={user}
                                  selSignupClientid={selectedKey}
                                  reloadNewsGridCondition={reloadNewsGridCondition}
                                />
                              </Item>
                              <Item title='Account'>
                                <ClientsApprovedAccess
                                  selectedKey={selectedKey}
                                  user={user}
                                  selSignupClientid={selectedKey}
                                  reloadNewsGridCondition={reloadNewsGridCondition}
                                />
                              </Item>
                              {user.client_multi_account_users === true && (
                                <Item title='Users'>
                                  <ClientsApprovedAccountUsers
                                    selectedKey={selectedKey}
                                    user={user}
                                    selSignupClientid={selectedKey}
                                  />
                                </Item>
                              )}
                              <Item title='Risk Matrix'>
                                <ClientsApprovedRiskMatrix
                                  selectedKey={selectedKey}
                                  user={user}
                                  selSignupClientid={selectedKey}
                                />
                              </Item>
                              <Item title='KYC'>
                                <ClientsApprovedKYC
                                  selectedKey={selectedKey}
                                  user={user}
                                  selSignupClientid={selectedKey}
                                />
                              </Item>

                              <Item title='Transactions'>
                                <ClientsApprovedTransactions
                                  selectedKey={selectedKey}
                                  user={user}
                                  selClientid={selectedKey}
                                  reloadNewsGridCondition={reloadNewsGridCondition}
                                />
                              </Item>

                              {user.client_account_user_security === true && (
                                <Item title='Geolocation'>
                                  <ClientsinSignupGeolocation
                                    selectedKey={selectedKey}
                                    user={user}
                                    selSignupClientid={selectedKey}
                                  />
                                </Item>
                              )}
                            </MultiView>
                          </div>
                        </div>
                      </Item>
                    </ResponsiveBox>
                  </div>
                </div>
              </Item>
            </ResponsiveBox>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientsApprovedPage;
