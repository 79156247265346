import moment from 'moment'

export function toSqlDateFormat(date) {
   const result = moment(date)
   return result.format('YYYY-MM-DD')
}

export function toSqlDateTimeFormat(datetime) {
   const result = moment(datetime)
   return result.format('YYYY-MM-DD HH:mm:ss')
}
