import React, { useState, useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import AlertPanel from '../user-panel/alert-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { useHttpClient } from '../../hooks/http-hook';
import { useAuth } from '../../contexts/auth';

const Header = ({ menuToggleEnabled, title, toggleMenu }) => {
  const { user } = useAuth();
  const [alertMessages, setAlertMessages] = useState([]);
  const [cntMessages, setCntMessages] = useState(0);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />
        <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
          <Button className={'user-button authorization'} width={210} height={'100%'} stylingMode={'text'}>
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  );
};

export default Header;
