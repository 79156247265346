import React, { useRef, useEffect, useState } from 'react';
import { toSqlDateFormat } from '../../utils/dateFormat';
import DocExchangeHelper from '../../modules/docexchange/DocExchangeHelper';
import FileUploader from 'devextreme-react/file-uploader';
import { Validator } from 'devextreme-react';
import {
  Form,
  Label,
  RequiredRule,
  SimpleItem,
  Item as FormItem,
  ButtonItem,
  ButtonOptions,
} from 'devextreme-react/form';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';

function ServiceCustomerDocsPopup(props) {
  const { hidePopup, selServiceId, selReqDocId, isDetails, doReload } = props;
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const uploadFileRef = useRef(null);

  const [docDetails, setDocDetails] = useState();
  const [docDownloadLink, setDocDownloadLink] = useState(null);

  useEffect(() => {
    const fetchRequiredDocData = async () => {
      try {
        const docDetails = await DocExchangeHelper.getDocumentDetails(selServiceId, selReqDocId);
        setDocDetails(docDetails);

        const link = (
          <a
            href='#'
            onClick={e => {
              e.preventDefault();
              downloadDocument(docDetails.file_name);
            }}
          >
            {docDetails.file_name}
          </a>
        );

        setDocDownloadLink(link);
      } catch (err) {
        notify('An error occurred while receiving the data.', 'error', 2000);
      }
    };

    if (selReqDocId && isDetails) {
      fetchRequiredDocData();
    }
  }, []);

  const docTemplateData = {
    docUsageAdvice: docDetails?.usage_advice || '',
    docComment: docDetails?.comment || '',
    validOn: docDetails?.validon || new Date(),
    validUntil: docDetails?.validuntil || '',
    file: null,
  };

  const saveRequiredDoc = async () => {
    try {
      const docData = new FormData();
      if (docTemplateData.docComment) docData.append('comment', docTemplateData.docComment);
      if (docTemplateData.docUsageAdvice) docData.append('usageAdvice', docTemplateData.docUsageAdvice);
      if (docTemplateData.file) docData.append('file', docTemplateData.file);
      if (docTemplateData.validOn) docData.append('validOn', toSqlDateFormat(docTemplateData.validOn));
      if (docTemplateData.validUntil) docData.append('validUntil', toSqlDateFormat(docTemplateData.validUntil));

      await DocExchangeHelper.uploadDocument(selServiceId, docData);
      notify('New document was successfully added', 'success', 2000);
    } catch (err) {
      notify('Error sending data', 'error', 2000);
    }
  };

  const validateForm = async noteFormRef => {
    const result = noteFormRef.validationGroup.validate();

    if (result.isValid) {
      await saveRequiredDoc();
      hidePopup(true);
      doReload(true);
    } else {
      notify('Please complete all required fields', 'error', 2000);
    }
  };

  const renderDownloadLink = () => {
    return docDownloadLink;
  };

  const downloadDocument = fileName => {
    DocExchangeHelper.getDocumentDownloadLink(selServiceId, selReqDocId)
      .then(response => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(response.data);
        fileReader.onloadend = async () => {
          if (fileReader.result) {
            const link = document.createElement('a');
            link.href = fileReader.result.toString();
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        };
      })
      .catch(e => {
        notify('Data loading error', 'error', 2000);
      });
  };

  const renderFileUploader = () => {
    return (
      <FileUploader
        id={'serviceCustomerFileUploader'}
        selectButtonText='Select a file'
        uploadMode='instantly'
        uploadFile={file => {
          docTemplateData.file = file;
        }}
        ref={uploadFileRef}
      >
        <Validator validationGroup='RequiredDocData'>
          <RequiredRule message='Select a file' />
        </Validator>
      </FileUploader>
    );
  };

  return (
    <Popup
      title='Required Customer Document'
      ref={popupRef}
      height={'auto'}
      width={'650px'}
      closeOnOutsideClick={true}
      showCloseButton={true}
      visible={true}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={7} />
        <Row ratio={2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <Form ref={noteFormRef} formData={docTemplateData} labelLocation={'left'} validationGroup='RequiredDocData'>
            <FormItem itemType='group' colCount={2} disabled={isDetails}>
              <SimpleItem
                dataField='validOn'
                editorType='dxDateBox'
                editorOptions={{ type: 'date', displayFormat: 'dd.MM.yyyy' }}
              >
                <Label location='top' text='Valid on' />
              </SimpleItem>
              <SimpleItem
                dataField='validUntil'
                editorType='dxDateBox'
                editorOptions={{ type: 'date', displayFormat: 'dd.MM.yyyy' }}
              >
                <Label location='top' text='valid until' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' disabled={isDetails}>
              <SimpleItem dataField='docUsageAdvice' isRequired={false} colSpan={2}>
                <Label text='Document Usage Advice' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' colCount={1} disabled={isDetails}>
              <SimpleItem
                dataField='docComment'
                editorType='dxTextArea'
                isRequired={false}
                editorOptions={{ height: 100 }}
              >
                <Label text='Comment' />
              </SimpleItem>
            </FormItem>
            <FormItem itemType='group' colCount={1}>
              {isDetails ? (
                <SimpleItem render={renderDownloadLink}>
                  <Label text='Download a file' />
                </SimpleItem>
              ) : (
                <SimpleItem dataField='file' render={renderFileUploader}>
                  <Label text='Upload document' />
                </SimpleItem>
              )}
            </FormItem>
            {!isDetails && (
              <ButtonItem>
                <ButtonOptions type={'success'} useSubmitBehavior={false} onClick={validateForm}>
                  <span className='dx-button-text'>Save</span>
                </ButtonOptions>
              </ButtonItem>
            )}
          </Form>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
}

export default ServiceCustomerDocsPopup;
