import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import './serviceProvider.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';

import Button from 'devextreme-react/button';

import NotesPopup from '../../ui_elements/NotesPopup';
import NoteEditPopup from '../../ui_elements/NoteEditPopup';
import HTTP from '../../api/HTTP';

const ServiceNotes = (props) => {
  const { selectedKey, selNotesType, user, selContactid } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [notesDataList, setNotesDatalist] = useState([]);
  const [notesKey, setNotesKey] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [reloadNotes, setReloadNotes] = useState(false);
  const [showNoteEditPopup, setShowNoteEditPopup] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const dataArray = await HTTP.get(`/base/getnotes/${selNotesType}/${selectedKey}`);
        setNotesDatalist(dataArray.data);
      } catch (err) {}
    };
    if (selNotesType && selectedKey) {
      fetchNotes();
    }
  }, [selNotesType, selectedKey, reloadNotes]);

  const stNotesDataList = new ArrayStore({
    key: 'noteid',
    data: notesDataList,
  });

  const dcNotesDataList = new DataSource({
    store: stNotesDataList,
  });

  const OpenNote = () => {
    setShowNoteEditPopup(true);
  };

  const AddNote = () => {
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadNotes(!reloadNotes);
  };

  const hideNodeEditPopup = () => {
    setShowNoteEditPopup(false);
  };

  return (
    <React.Fragment>
      <NotesPopup
        showPopup={showPopup}
        hidePopup={hidePopup}
        selsubj_type={selNotesType}
        // selsubj_uuid={}
        selsubj_int={selectedKey}
        selNotesKey={notesKey}
        initsubject='Service Provider'
      />
      <NoteEditPopup showPopup={showNoteEditPopup} hidePopup={hideNodeEditPopup} selNotesKey={notesKey} />
      <ResponsiveBox>
        <Col ratio={0.3} />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='routing-notes-btn'>
            <Button width={120} height={50} text='Add' type='normal' stylingMode='outlined' onClick={AddNote} />
          </div>
          <div id='routing-notes-btn-2'>
            <Button width={120} height={50} text='Open' type='normal' stylingMode='outlined' onClick={OpenNote} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div>
            <DataGrid
              id='serviceprovider-notes-grid'
              dataSource={dcNotesDataList}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={false}
              showColumnHeaders={true}
              onFocusedRowChanged={(e) => {
                setNotesKey(e.row.key);
              }}
            >
              <FilterRow visible={false} />
              <Column dataField='noteid' visible={false}></Column>
              <Column dataField='notedatetime' caption='Date' dataType='datetime' width={120} visible={true}></Column>
              <Column dataField='content' encodeHtml={false} caption='Note' dataType='string'></Column>
              <Column dataField='combined_name' alignment='left' caption='User' width={150} visible={true}></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ServiceNotes;
