import { useState, useCallback, useRef, useEffect } from 'react';
import HTTP from '../api/HTTP';
import { useHttpClient } from './http-hook';

export const useCaseNote = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const sendCaseNoteSilent = useCallback(async (user, clientid, caseSituation, notecomment, transactionid) => {
    try {
      await HTTP.post(
        '/cases/sendcasenotesilent',
        JSON.stringify({
          userid: user.id,
          clientid: clientid,
          caseSituation: caseSituation,
          portalid: user.portalid,
          notecomment: notecomment,
          transactionid: transactionid,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const checkClientApprovalState = useCallback(async (user, clientid) => {
    try {
      await HTTP.get(`/client/check-client-approval-state/${clientid}`);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    sendCaseNoteSilent: sendCaseNoteSilent,
    checkClientApprovalState: checkClientApprovalState,
  };
};
