import React from 'react';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import './home.scss';
import '../../global.scss';

const HomePage = () => {
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}></div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
