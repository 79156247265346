export const navigation = [
  {
    DASH: true,
    text: 'Dashboard',
    path: '/home',
    icon: 'home',
  },
  {
    OPNS: true,
    text: 'Operations',
    path: '/operations',
    icon: 'user',
  },
  {
    CLINT: true,
    text: 'Clients',
    path: '/clients',
    icon: 'group',
  },
  {
    PSRV: true,
    text: 'Services',
    icon: 'runner',
    path: '/services',
  },
  {
    FX: true,
    text: 'Forex',
    icon: 'chart',
    path: '/forex',
  },
  {
    RPTNG: true,
    text: 'Reporting',
    icon: 'bulletlist',
    isExpanded: false,
    items: [
      {
        RTAN: true,
        text: 'Transactions',
        icon: 'spinnext',
        path: '/transactions',
      },
      {
        ROVER: true,
        text: 'Cubes',
        icon: 'spinnext',
        path: '/reporting',
      },
    ],
  },
  {
    COMCN: true,
    text: 'Communication',
    icon: 'tel',
    isExpanded: false,
    items: [
      {
        CMSG: true,
        text: 'Client Messaging',
        icon: 'spinnext',
        path: '/messages',
      },
      {
        CMAIL: true,
        text: 'Client Mailing',
        icon: 'spinnext',
        path: '/mailing',
      },
      {
        CCALL: true,
        text: 'Client Video Call',
        icon: 'spinnext',
        path: '/calendar',
      },
    ],
  },
   {
    GDEFS: true,
    text: 'Settings',
    path: '/globaldefinitions',
    icon: 'preferences',
  }, 
];
