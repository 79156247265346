import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import Button from 'devextreme-react/button';
import './operations.scss';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column, FilterRow, Editing, Button as DGButton } from 'devextreme-react/data-grid';

import CasesNotesPop from './CasesNotesPop';
import CasesNotesToPop from './CasesNotesToPop';
import HTTP from '../../api/HTTP';

const CasesNotesTab = (props) => {
  const { tabVisibleIndex, caseDetails, user, selPartnerPortal, getReload, reloadNewsGridCondition } = props;
  const fkey = caseDetails && caseDetails.entryid;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [notesDataList, setNotesDatalist] = useState([]);
  const [notesGridRecord, setNotesGridRecord] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadNotesData, setReloadNotesData] = useState(false);

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);

  const [caseSituationAction, setCaseSituationAction] = useState(false);

  const [compDay, setCompDay] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await HTTP.get(`/cases/onecasenotes/${selPartnerPortal}/${fkey}`);
        setNotesDatalist(dataArray.data);
      } catch (err) {}
    };
    if ((fkey && selPartnerPortal) || reloadNotesData) {
      fetchCases();
    }
  }, [fkey, tabVisibleIndex, selPartnerPortal, reloadNotesData]);

  const navigatetoAddCaseNote = async () => {
    await setContactClientid(notesGridRecord.clientid);
    await setOpenedType(1);
    await setShowPopup(true);
  };

  const hidePopup = async () => {
    await setShowPopup(false);
    await getReload(true);
    await reloadNewsGridCondition(true);
    await setReloadNotesData(true);
  };

  const openAddNotesToPopup = async () => {
    setContactClientid(notesGridRecord.clientid);
    setCaseSituationAction(true);
    setNoteCaseSituation(notesGridRecord.case_situation);
    setShowCasesNotesTo(true);
  };

  const contactSelectedClient = async () => {
    setContactClientid(notesGridRecord.clientid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(notesGridRecord.merchant_portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = async () => {
    setContactClient(false);
    setContactClientid(notesGridRecord.clientid);
    setContactPortalid(notesGridRecord.merchant_portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = async () => {
    setContactClient(false);
    setContactClientid(notesGridRecord.clientid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);

    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
    await setCaseSituationAction(false);

    await reloadNewsGridCondition(true);
    await setReloadNotesData(true);
  };

  const DocBtnVisible = (e) => {
    if (e.row.data.inquiryid === -1) {
      return true;
    } else {
      return false;
    }
  };

  const caseNoteOpen = (e) => {
    user.casenote = {
      selEntryid: e.row.data.entryid,
    };
    history.push({
      pathname: '/casenote',
    });
  };

  const gridCellData = (gridData) => {
    return gridData.data['lastchat_from'];
  };

  const renderChatDateCell = (cellData) => {
    if (cellData.value !== null) {
      const gDay = cellData.value.toISOString().split('T')[0];
      // console.log(111, compDay, gDay);
      if (compDay === gDay) {
        return <div style={{ color: 'blue' }}>{gridCellData(cellData) + String.fromCharCode(10) + cellData.text}</div>;
      } else {
        return (
          <div style={{ color: 'default' }}>{gridCellData(cellData) + String.fromCharCode(10) + cellData.text}</div>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <CasesNotesPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        user={user}
        entryid={notesGridRecord.entryid}
        openedToEdit={openedType}
        caseNumber={caseDetails && caseDetails.entryid}
        contactClientid={contactClientid}
      />
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={notesGridRecord.entryid}
        caseEntryid={notesGridRecord.case_entryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        caseSituationAction={caseSituationAction}
      />
      <ResponsiveBox>
        <Col ratio={0.4} screen='md lg sm' />
        <Col ratio={2.3} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />

          <div id='hm-btn-section-1'>
            <p>Merchant</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Contact'
              type='default'
              stylingMode='outlined'
              onClick={contactClientMerchant}
            />
          </div>
          <div id='hm-btn-section-1'>
            <p>Note</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button
              width={120}
              height={36}
              text='Add'
              type='default'
              stylingMode='outlined'
              onClick={navigatetoAddCaseNote}
            />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div>
            <DataGrid
              id='ops-cases-notes-grid'
              dataSource={notesDataList}
              keyExpr={'entryid'}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              wordWrapEnabled={true}
              showRowLines={true}
              showColumnHeaders={true}
              showColumnLines={true}
              onFocusedRowChanged={(e) => {
                setNotesGridRecord(e.row.data);
              }}
              onRowDblClick={() => {
                caseNoteOpen();
              }}
            >
              <Editing mode='row' useIcons={true} />
              <FilterRow visible={false} />
              <Column dataField='entryid' visible={false} />
              <Column dataField='case_entryid' visible={false} />
              <Column
                dataField='datenote'
                caption='Note Date'
                dataType='datetime'
                width={80}
                visible={true}
                // cellRender={renderGridCell}
              />
              <Column dataField='case_content' visible={false} />
              <Column dataField='subject' caption='subject' visible={false} />
              <Column dataField='content' caption='Notes' encodeHtml={false} dataType='string' visible={true} />
              <Column dataField='from_sender' caption='From' width={120} visible={true} />
              <Column
                dataField='to_receivers'
                caption='To'
                width={120}
                encodeHtml={false}
                dataType='string'
                visible={true}
              />
              <Column type='buttons' caption='Docs/Chat' width={90}>
                <DGButton
                  hint='open Docs/Chat'
                  icon='activefolder'
                  visible={DocBtnVisible}
                  disabled={false}
                  onClick={caseNoteOpen}
                />
              </Column>
              <Column
                dataField='datechat'
                caption='Last Chat'
                dataType='datetime'
                width={100}
                visible={true}
                cellRender={renderChatDateCell}
              />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesNotesTab;
