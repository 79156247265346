import React, { useState, useEffect } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import MultiView from 'devextreme-react/multi-view';
import Tabs from 'devextreme-react/tabs';

import CasesNotesTab from './CasesNotesTab';
import ClientsInquiry from './ClientInquiries';
import MessageThreadPage from '../../messages/MessageThreadPage';
import ClientDocuments from './ClientDocuments';
import HTTP from '../../../api/HTTP';

const ClientsComms = (props) => {
  const { user, selClientId, clientPortalid, refreshCRMActivity, refreshActivity } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tabIndex, setTabIndex] = useState(0);
  const [selPartnerPortal, setSelPartnerPortal] = useState(user.portalid);
  const [partnerProviderClientChatid, setPartnerProviderClientChatid] = useState();

  useEffect(() => {
    const fetchPartnerProviderClientChat = async () => {
      try {
        const dataArray = await HTTP.get(
          `/client/partner-provider-client-chat/${clientPortalid}/${user.portalid}/${selClientId}`
        );
        setPartnerProviderClientChatid(dataArray.data[0].chatid);
      } catch (err) {}
    };
    if (user.portalid && clientPortalid && selClientId) {
      fetchPartnerProviderClientChat();
    } else {
      setPartnerProviderClientChatid(-1);
    }
  }, [user.portalid, clientPortalid, selClientId]);

  return (
    <div id='client-comms-box'>
      <Tabs
        selectedIndex={tabIndex}
        width='100%'
        visible={true}
        onItemClick={(e) => {
          setTabIndex(e.itemIndex);
        }}
      >
        <Item text='Notes' />
        <Item text='Chat' />
        <Item text='Documents' />
      </Tabs>
      <MultiView height={740} animationEnabled={false} selectedIndex={tabIndex}>
        <Item title='Notes'>
          <CasesNotesTab
            tabVisibleIndex={tabIndex}
            selClientId={selClientId}
            selPartnerPortal={selPartnerPortal}
            user={user}
            refreshCRMActivity={refreshCRMActivity}
            // getReload={getReloadCondition}
          />
        </Item>

        <Item title='Chat'>
          {partnerProviderClientChatid && partnerProviderClientChatid > -1 && (
            <MessageThreadPage
              messageid={parseInt(partnerProviderClientChatid)}
              openedBy={clientPortalid}
              portalType={2}
              selClientId={selClientId}
              refreshActivity={refreshActivity}
              own_portalid={user.portalid}
              tgt_portalid={clientPortalid}
            />
          )}
        </Item>

        <Item title='Documents'>
          <ClientDocuments user={user} selClientId={selClientId} />
        </Item>
      </MultiView>
    </div>
  );
};

export default ClientsComms;
