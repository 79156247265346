import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import { useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';

import './Forex.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import ForexSetup from './ForexSetup';
import ForexTrades from './ForexTrades';
import HTTP from '../../api/HTTP';

const FxTradingPage = () => {
  // const selInquiryId = 27;
  const { user } = useAuth();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [forexAccountid, setForexAccountid] = useState([]);

  const fetchForexExtAccountid = async () => {
    try {
      const dataArray = await HTTP.get(`/forex/extaccountprovider/${user.providerid}`);
      setForexAccountid(dataArray.data[0].baseaccountid);
    } catch (err) {}
  };

  useEffect(() => {
    if (!user) {
      history.push({
        pathname: '/',
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchForexExtAccountid();
    }
  }, []);

  function navigateToHome() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg xs sm' row={0} col={0} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='fxtrading-box'>
                <ForexSetup user={user} extAccountId={forexAccountid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={0} col={1} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='fxtrading-box'></div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg xs sm' row={1} col={0} colspan={2} />
          <div className={'content-block'}>
            <div className={'dx-card'}>
              <div id='fxtrading-line'>
                <ForexTrades user={user} extAccountId={forexAccountid} />
              </div>
            </div>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={3} col={1} />
          <div id={'client-close-button'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default FxTradingPage;
