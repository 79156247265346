import React, { useEffect, useState } from 'react';
import './inquiries.css';

import { Item } from 'devextreme-react/responsive-box';
import { Popup } from 'devextreme-react/popup';
import { useAuth } from '../../contexts/auth';
import { useHttpClient } from '../../hooks/http-hook';
import { DocumentRequestFormData } from '../docexchange/forms';
import ServiceProviderHelper from '../../services/ServiceProviderHelper';
import DocExchangeHelper from '../docexchange/DocExchangeHelper';
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { Button } from 'devextreme-react/button';

function AddInquiry(props) {
  const { user: currentUser } = useAuth();

  const { showPopup, hidePopup, selSignupClientId } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [noteData, setNoteData] = useState({});
  const [formData, setFormData] = useState(new DocumentRequestFormData());
  const [requestTypes, setRequestTypes] = useState([]);
  const [serviceTemplates, setServiceTemplates] = useState([]);
  const [serviceProviderServices, setServiceProviderServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ServiceProviderHelper.getServiceProviderServices(currentUser.providerId).then(response => {
      setServiceProviderServices(response.data);
    });
  }, []);

  const serviceSelected = serviceId => {
    DocExchangeHelper.getRequiredCustomerDocumentsForService(serviceId).then(data => {
      setServiceTemplates(data.items);
    });
  };

  const handleSubmit = e => {
    setLoading(true);
    DocExchangeHelper.createNewDocumentsRequestForClient(
      selSignupClientId,
      currentUser.userId,
      currentUser.portalid,
      formData
    )
      .then(() => {
        notify('New Request sent successfully', 'success', 2000);
      })
      .catch(() => {
        notify('An error occurred while request sent.', 'error', 2000);
      })
      .finally(() => {
        setLoading(false);
        hidePopup();
        setFormData(new DocumentRequestFormData());
      });

    return false;
  };

  return (
    <>
      <Popup
        title={'Add New Request'}
        width={500}
        height={400}
        visible={showPopup}
        resizeEnabled={true}
        showCloseButton={true}
        onHiding={() => {
          hidePopup(true);
        }}
      >
        <div id='add-query-pop-form' className='form-container'>
          <Form formData={formData} showColonAfterLabel={true} id={'form'}>
            <Item
              dataField='title'
              label={{ text: 'Title', alignment: 'left', location: 'top' }}
              editorOptions={{
                displayExpr: 'label',
                stylingMode: 'underlined',
              }}
            />
            <Item
              dataField='description'
              editorType='dxTextArea'
              editorOptions={{
                height: '150px',
              }}
              label={{
                alignment: 'left',
                location: 'top',
              }}
            />
            <Item>
              <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type={'default'}
                    useSubmitBehavior={false}
                    onClick={handleSubmit}
                    text='Send'
                    stylingMode='contained'
                    width={120}
                    height={40}
                  >
                    <span className='dx-button-text'>
                      {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Save'}
                    </span>
                  </Button>
                  <div style={{ marginLeft: '10px' }}>
                    <Button
                      width={120}
                      height={40}
                      text='close'
                      type='normal'
                      stylingMode='contained'
                      onClick={() => {
                        hidePopup();
                        setFormData(new DocumentRequestFormData());
                      }}
                    />
                  </div>
                </div>
              </div>
            </Item>
          </Form>
        </div>
      </Popup>
    </>
  );
}

export default AddInquiry;
