import React, { useEffect, useRef, useState } from 'react';
import './ClientsApprovedPage.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';
import { CheckBox } from 'devextreme-react/check-box';

import { useCaseNote } from '../../hooks/note-hook';

const ClientTransactionPop = props => {
  const { showPopup, hidePopup, user, selTransactionRecord, clientid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [requestSourceOfFunds, setRequestSourceOfFunds] = useState(true);
  const { sendCaseNoteSilent } = useCaseNote();

  const FormatDateString = anDateString => {
    const anDate = anDateString
      .slice(0, 16)
      // .replace(/-/g, "/")
      .replace('T', ' at ');
    return anDate;
  };

  const ExecuteTransactionRequest = async () => {
    if (requestSourceOfFunds) {
      const messageText =
        FormatDateString(selTransactionRecord.transferdate) +
        '\n' +
        selTransactionRecord.servicename +
        ' - ' +
        selTransactionRecord.assetname +
        ' - ' +
        selTransactionRecord.operation +
        '\n' +
        selTransactionRecord.assetamount +
        ' ' +
        selTransactionRecord.assetcalcunit +
        ' for ' +
        selTransactionRecord.currency +
        ' ' +
        selTransactionRecord.currencyamount;

      await sendCaseNoteSilent(user, clientid, 21, messageText, selTransactionRecord.transactionid);
    }
    hidePopup(true);
  };

  return (
    <Popup
      title={'Transaction Request'}
      width={500}
      height={300}
      visible={showPopup}
      showCloseButton={true}
      onHiding={() => {
        hidePopup(true);
      }}
    >
      <ResponsiveBox>
        <Row ratio={6} />
        <Row ratio={0.2} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='transaction-request-area-1'>
            <table>
              <tbody>
                <tr>
                  <td width='150px'>
                    {selTransactionRecord.transferdate && FormatDateString(selTransactionRecord.transferdate)}
                  </td>
                  <td width='400px'>
                    {selTransactionRecord &&
                      selTransactionRecord.servicename +
                        ' - ' +
                        selTransactionRecord.assetname +
                        ' - ' +
                        selTransactionRecord.operation}
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td width='150px'> </td>
                  <td width='400px'>
                    {selTransactionRecord &&
                      selTransactionRecord.assetamount +
                        ' ' +
                        selTransactionRecord.assetcalcunit +
                        ' for ' +
                        selTransactionRecord.currency +
                        ' ' +
                        selTransactionRecord.currencyamount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id='transaction-request-cbx'>
            <CheckBox
              text='Request Source of Funds of the Transaction listed above'
              value={requestSourceOfFunds}
              onValueChanged={e => {
                if (e.value === true) {
                  setRequestSourceOfFunds(!requestSourceOfFunds);
                }
              }}
            />
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Execute'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={ExecuteTransactionRequest}
              />
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={50}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={hidePopup}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

export default ClientTransactionPop;
